<template>
  <div v-if="isSuccessCart" class="p-message p-component p-message-error" role="alert">
    <div class="p-message-wrapper">
      <span class="blink p-message-icon pi pi pi-info-circle"></span>
      <div class="p-message-text">
        <div class="font-bold">
            {{itemInCart}} surat dalam Troli anda.
          Sila ke<a class="ml-1 text-blue" @click="toCart()" href="#">
            <span class="underline">Troli</span>
          </a>
          dan klik "Teruskan" bagi meneruskan bidaan anda.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      isSuccessCart: false,
      itemInCart: 0,
    };
  },
  mounted: function () {
    this.getItemInCart();
  },
  methods: {
    toCart() {
      this.$router.push({ name: "cart" });
    },
    getItemInCart() {
      axios
        .get("/itemInCart", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            user_id: localStorage.getItem("id"),
          },
        })

        .then((response) => {
        //   console.log("getItemInCart", response);
          if (response.data.success === true) {
            this.isSuccessCart = true;
            this.itemInCart = response.data.cart;
          } else {
            this.isSuccessCart = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>