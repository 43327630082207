<template>
  <div>
      <VueSidebarMenuAkahon />
  </div>
  <div v-if="isLoading" class="fadein animation-duration-400">
    <div class="flex align-items-center justify-content-center container-height">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <div v-else style="min-height: 70vh" class="fadein animation-duration-400">
    <div v-if="isSuccess" class="grid justify-content-center">
      <div class="col-12 lg:col-11">
        <Card>
          <template #title>
            <div class="grid justify-content-between">
              <div class="font-normal">Rekod Bidaan Hari Ini : <span class="white-space-nowrap">{{ date }}</span></div>
              <div class="text-xs align-self-center fst-italic fw-lighter text-danger">*Sistem akan menjana pemenang setelah masa bidaan tamat pada {{lelongDetail.masatamat_lelong}}</div>
            </div>
          </template>
          <template #content>
            <Accordion :multiple="true">
              <AccordionTab v-for="bid in listBid" v-bind:key="bid">
                <template #header>
                  <div class="flex justify-content-between w-100">
                    <div class="align-self-center" style="margin: 5px">
                      {{ bid.caw_name }}
                    </div>
                    <div class="style-info">
                      Jumlah RM{{
                        bid.total_bidaan
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                      }}
                    </div>
                  </div>
                </template>
                <div class="overflow-x-auto">
                  <table class="table text-center table-bordered table-hover">
                    <tbody>
                      <tr class="table-light">
                        <td>Bil</td>
                        <td style="min-width: 240px">No Rujukan</td>
                        <td style="min-width: 210px">Tarikh/Masa Bidaan</td>
                        <td>Berat</td>
                        <td style="min-width: 120px">Harga Bida</td>
                      </tr>
                      <tr
                        v-for="(item, index) in bid.lelongbid"
                        v-bind:key="item"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#marhunModal"
                            v-on:click="marhunModal(item)"
                            >{{ item.norujukan }}</a
                          >
                        </td>
                        <td>{{ item.masa_bidaan }}</td>
                        <td>{{ item.berat }}g</td>
                        <td>
                          RM{{
                            item.hargabida
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                          }}
                        </td>
                      </tr>
                      <tr style="text-transform: uppercase">
                        <th colspan="3">Jumlah</th>
                        <th>{{ bid.total_berat }}g</th>
                        <th>
                          RM{{
                            bid.total_bidaan
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                          }}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </AccordionTab>
            </Accordion>
          </template>
        </Card>
      </div>
    </div>
    <div v-else>
      <div
        class="flex align-items-center justify-content-center container-height"
      >
        <div class="grid justify-content-center">
          <div class="col-12 mb-4 text-center">
            <i class="pi pi-book text-8xl text-400"></i>
          </div>
          <div class="col-12 text-center">
            <span class="text-400">Tiada Rekod Bidaan Hari Ini.</span>
          </div>
          <div class="col-12 text-center">
            <span class="text-400"
              >Sila rujuk halaman
              <router-link v-bind:to="{ name: 'home' }">Utama</router-link>
              untuk jadual lelongan.</span
            >
          </div>
        </div>
      </div>
    </div>

    <!-- MARHUN MODAL -->
    <div
      class="modal fade"
      id="marhunModal"
      tabindex="-1"
      aria-labelledby="marhunModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="marhunModalLabel">
              {{ theData.norujukan }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="grid">
              <div
                class="col-12 lg:col-6"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  min-height: 300px;
                  min-width: 300px;
                  max-height: 600px;
                  max-width: 600px;
                "
              >
                <item-master-image-modal
                  v-if="theData.filemarhun"
                  v-bind:key="reloadImageComponent"
                  v-bind:norujukan="theData.norujukan"
                  v-bind:filemarhun_id="theData.filemarhun[0].id"
                ></item-master-image-modal>
                <p v-else style="text-align: center; width: 100%">
                  Image Unavailable
                </p>
              </div>
              <div class="col-12 text-center lg:col-6">
                <div class="overflow-x-auto">
                  <table class="table table-bordered">
                    <tbody>
                      <tr class="table-light">
                        <th>Bil</th>
                        <th style="min-width: 115px">Jenis Marhun</th>
                        <th style="min-width: 42px">Karat</th>
                        <th style="min-width: 42px">Berat</th>
                        <th style="min-width: 102px">Harga Asas</th>
                      </tr>
                      <tr
                        v-for="(marhun, index) in theData.lelongbidmarhun"
                        v-bind:key="marhun"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ marhun.marhun }}</td>
                        <td>{{ marhun.karat }}</td>
                        <td>{{ marhun.berat }}g</td>
                        <td>
                          RM{{
                            marhun.hargaasas
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                          }}
                        </td>
                      </tr>
                      <tr style="text-transform: uppercase">
                        <td colspan="3">Jumlah</td>
                        <td>{{ theData.berat }}g</td>
                        <td>
                          RM{{
                            theData.hargaasas
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row justify-content-center">
                  <div class="col-6">
                    <label class="col-form-label">Jumlah Harga Bida</label>
                  </div>
                  <div class="col-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text">RM</span>
                      <span class="form-control font-bold input-group-text">{{
                        theData.hargabida
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <Button
              class="p-button-sm"
              label="Kembali"
              data-bs-dismiss="modal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ItemMasterImageModal from "../list/ItemMasterImageModal.vue";

export default {
  name: "BidDetail",
  components: {
    "item-master-image-modal": ItemMasterImageModal,
  },
  data() {
    return {
      listBid: [],
      isLoading: false,
      isSuccess: false,
      theData: "",
      date: "",
      lelongDetail: "",
      reloadImageComponent: 0,
      // userId: localStorage.getItem("id"),
    };
  },
  mounted: function () {
    this.bidRecord();
    this.getDate();
  },
  methods: {
    getDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = dd + "-" + mm + "-" + yyyy;
      this.date = today;
    },
    bidRecord() {
      this.isLoading = true;
      axios
        .get("/bidRecordByCawangan/" + localStorage.getItem("id"), {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          // console.log("bidRecord", response);
          if (response.data.success == true) {
            this.isLoading = false;
            this.isSuccess = true;
            this.listBid = response.data.lelongbidder;
            this.lelongDetail = response.data.lelong;
          } else {
            this.isLoading = false;
            this.isSuccess = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    marhunModal(value) {
      this.theData = value;
      this.reloadImageComponent += 1;
      console.log(value);
    },
  },
};
</script>

<style scoped>
.container-height {
  min-height: 70vh;
}

.style-info {
  background: #e4f8f0;
  border: solid #1ea97c;
  border-width: 0px;
  color: #1ea97c;
  font-size: 1rem;
  align-items: center;
  display: flex;
  padding: 0.4rem 0.9rem;
  margin: 0;
  border-radius: 6px;
  font-weight: 400;
}
</style>