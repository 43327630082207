<template>
  <div v-if="isLoading" class="fadein animation-duration-400">
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <div v-else class="fadein animation-duration-300">
    <div v-if="responseData">
      <div class="overflow-x-auto">
        <table class="table table-sm table-bordered table-hover">
          <tbody>
            <tr class="table-light font-bold">
              <th>Bil</th>
              <th style="min-width: 200px">Cawangan Ar-Rahn</th>
              <th style="min-width: 120px" class="text-center">Masa Mula</th>
              <th style="min-width: 120px" class="text-center">Masa Tamat</th>
            </tr>
            <tr v-for="(item, index) in response" :key="item.id">
              <td>{{ index + 1 }}</td>
              <td>{{ item.caw_name }}</td>
              <td class="text-center">{{ item.masamula_lelong }}</td>
              <td class="text-center">{{ item.masatamat_lelong }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Button label="Lihat Senarai" class="p-button-sm" v-on:click="toList()" />
    </div>
    <div v-else>
      <table class="table table-sm table-bordered table-hover">
        <tbody>
          <tr class="table-light font-bold">
            <td>Bil</td>
            <td>Cawangan Ar-Rahn</td>
            <td>Masa Mula</td>
            <td>Masa Tamat</td>
          </tr>
          <tr>
            <td colspan="100" class="text-center text-500">Tiada Data</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useAuthenticationUserStore } from "@/store/modules/authentication";

export default {
  data() {
    return {
      responseData: false,
      isLoading: false,
      response: Array,
    };
  },
  mounted: function () {
    this.liveData();
  },
  methods: {
    liveData() {
      this.isLoading = true;
      axios
        .get("/listcawlelongtoday", {
          headers: {
            Authorization: "Bearer " + useAuthenticationUserStore().getToken,
          },
        })
        .then((response) => {
          // console.log("liveData", response);
          if (response.data.success == true) {
            this.responseData = true;
            this.response = response.data.lelong;
            localStorage.setItem(
              "lelong_id",
              response.data.lelong[0].lelong_id
            );
            this.isLoading = false;
          } else {
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toList() {
      this.$router.push({ name: "list" });
    },
  },
};
</script>