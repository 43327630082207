<template>
  <div v-if="isLoading" class="fadein animation-duration-400">
    <div
      class="flex align-items-center justify-content-center container-height"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <div v-else class="fadein animation-duration-400">
    <div v-if="isSuccess">
      <div class="p-message p-component p-message-info" role="alert">
        <div class="p-message-wrapper">
          <span class="blink p-message-icon pi pi pi-info-circle"></span>
          <div class="p-message-text">
            Gambar sedang disemak oleh bahagian pengurusan. Sila tunggu sebentar.
          </div>
        </div>
      </div>
      <div class="overflow-x-auto">
        <table class="table table-bordered text-center table-hover">
          <tbody>
            <tr>
              <th>#</th>
              <th>ID Gambar</th>
              <th>Masa Muat Naik</th>
              <th>Gambar</th>
              <th>Tindakan</th>
            </tr>
            <tr v-for="(receipt, index) in listReceipts" v-bind:key="receipt">
              <td>{{ index + 1 }}</td>
              <td>{{ receipt.id }}</td>
              <td>{{ receipt.masa_upload }}</td>
              <td>
                <receipt-image v-bind:fileResitId="receipt.id"></receipt-image>
              </td>
              <td>
                <button
                  class="btn m-1 btn-danger"
                  @click="openPhotoModal(receipt)"
                >
                  Padam
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Dialog
        header="Padam Gambar"
        v-model:visible="isDisplayPhoto"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '50vw' }"
        :modal="true"
      >
        <div class="text-center m-2">
          <h1><i class="bi bi-exclamation-triangle text-warning"></i></h1>
          <h5>ADAKAH ANDA PASTI UNTUK PADAM GAMBAR INI?</h5>
          ID Gambar: {{ selectPhoto.id }}
          <p class="text-danger text-sm mt-3">
            <i>*Tindakan ini tidak dapat dikembalikan</i>
          </p>
        </div>
        <template #footer>
          <Button
            class="p-button-sm p-button-secondary"
            label="Batal"
            @click="closePhotoModal()"
          />
          <Button
            class="p-button-sm p-button-danger"
            label="Padam"
            @click="deletePhotoResit(selectPhoto)"
          />
        </template>
      </Dialog>
    </div>
    <div v-else>Tiada Gambar Yang Dimuat Naik</div>
  </div>
</template>

<script>
import axios from "axios";
import ReceiptImage from "./ReceiptImage.vue";

export default {
  emits: ["reloadComponent"],
  props: {
      lelong_id: "",
  },
  mounted: function () {
    this.listReceipt();
    // this.getInvoice();
  },
  components: {
    "receipt-image": ReceiptImage,
  },
  data() {
    return {
      isLoading: false,
      isSuccess: false,
      listReceipts: "",
      isDisplayPhoto: false,
      selectPhoto: "",
      fileresit_id: "",
    };
  },
  methods: {
    listReceipt() {
      this.isLoading = true;
      axios
        .get("/listPhotoResit", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            user_id: localStorage.getItem("id"),
            lelong_id: this.lelong_id,
          },
        })
        .then((response) => {
          //   console.log("listReceipt", response);
          this.isLoading = false;
          if (response.data.success == true) {
            this.isSuccess = true;
            this.listReceipts = response.data.file_resit;
          } else {
            this.isSuccess = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    openPhotoModal(value) {
      this.isDisplayPhoto = true;
      this.selectPhoto = value;
      this.fileresit_id = value.id;
    },
    closePhotoModal() {
      this.isDisplayPhoto = false;
      this.selectPhoto = "";
      this.fileresit_id = "";
    },
    deletePhotoResit(e) {
      axios
        .post(
          "/deletePhotoResit",
          { fileresit_id: this.fileresit_id },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.success == true) {
            this.isDisplayPhoto = false;
            this.$swal.fire({
              icon: "success",
              title: "Gambar Resit Berjaya Dipadam",
              confirmButtonColor: "#344871",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
            this.$emit("reloadComponent");
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
  },
};
</script>