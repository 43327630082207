<template>
  <the-template>
    <div class="grid">
      <div class="col-12 pt-2">
        <result-notification></result-notification>
      </div>
      <div class="col-12">
        <item-in-cart-notification></item-in-cart-notification>
      </div>
      <div class="col-12 lg:col-12">
        <lelong-today></lelong-today>
        <lelong-next></lelong-next>
        <current-karat></current-karat>
      </div>
    </div>
  </the-template>
</template>

<script>
import LelongToday from "./LelongToday.vue";
import LelongNext from "./LelongNext.vue";
import CurrentKarat from "./CurrentKarat.vue";
import ResultNotification from "./ResultNotification.vue";
import ItemInCartNotification from "./ItemInCartNotification.vue";

export default {
  components: {
    "lelong-today": LelongToday,
    "lelong-next": LelongNext,
    "current-karat": CurrentKarat,
    "result-notification": ResultNotification,
    "item-in-cart-notification": ItemInCartNotification,
  },
};
</script>

