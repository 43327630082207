<template>
  <div v-if="!nextButton">
    <h5>Pilih Surat</h5>
    <p>{{ selectedRadio.cawangan }}</p>
    <div class="container">
      <table class="table table-bordered">
        <tbody>
          <tr v-for="data in selectedRadio.sag" v-bind:key="data">
            <td>
              <input type="checkbox" v-bind:value="data" v-model="listSag" />
              <!-- <input
                type="checkbox"
                v-bind:value="data.total_payment"
                v-model="totalSelect"
              /> -->
            </td>
            <td>{{ data.norujukan }}</td>
            <td>RM{{ data.total_payment }}</td>
          </tr>
        </tbody>
      </table>
      <p>Jumlah Dipilih: RM{{ sum }}</p>
      <div class="d-flex justify-content-between">
        <button
          class="btn btn-secondary"
          v-on:click="$emit('back-is-click', false)"
        >
          Kembali
        </button>
        <button
          ref="nextButton"
          v-on:click="clickNextButton()"
          class="btn btn-primary"
        >
          Teruskan
        </button>
      </div>
    </div>
  </div>
  <div v-else>
    <select-finalise
      v-bind:finalSurat="listSag"
      v-bind:totalHarga="sum"
      v-on:back-is-click="backIsClick"
    ></select-finalise>
  </div>
</template>

<script>
import SelectFinalise from "./SelectFinalise.vue";

export default {
  emits: ["back-is-click"],
  components: {
    "select-finalise": SelectFinalise,
  },
  props: {
    selectedRadio: "",
  },
  data() {
    return {
      listSag: [],
      nextButton: false,
    };
  },
  methods: {
    clickNextButton() {
      this.nextButton = true;
    },
    backIsClick(value) {
      this.nextButton = value;
    },
  },
  computed: {
    sum() {
      return this.listSag.reduce(function (a, item) {
        return parseInt(a) + parseInt(item.total_payment);
      }, 0);
    },
  },
};
</script>