<template>
  <Card style="margin-bottom: 1em">
    <template #title>
      <div class="flex justify-content-center flex-wrap card-container">
        <div class="font-semibold">Lelongan Hari Ini : <span class="white-space-nowrap">{{ date }}</span></div>
        <Button
          class="button-standard p-button-rounded p-button-outlined"
          icon="pi pi-refresh"
          @click="toggleReloadComponent()"
        />
      </div>
    </template>
    <template #content>
      <lelong-today-detail v-bind:key="componentKey"></lelong-today-detail>
    </template>
  </Card>
</template>

<script>
import LelongTodayDetail from "./LelongTodayDetail.vue";

export default {
  components: {
    "lelong-today-detail": LelongTodayDetail,
  },
  data() {
    return {
      date: String,
      componentKey: 0,
    };
  },
  created() {
    this.getDate();
  },

  methods: {
    toggleReloadComponent() {
      this.componentKey += 1;
    },
    getDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = dd + "-" + mm + "-" + yyyy;
      this.date = today;
    },
  },
};
</script>

<style scoped>
.button-standard {
  height: 30px !important;
  width: 30px !important;
  margin-top: 3px !important;
}
</style>