<template>
  <the-template>
    <div v-if="loading" class="fadein animation-duration-400">
      <div
        class="flex align-items-center justify-content-center container-height"
      >
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div else style="min-height: 70vh">
      <div v-if="status">
        <div
          class="
            flex
            align-items-center
            justify-content-center
            container-height
            fadein
            animation-duration-400
          "
        >
          <Card>
            <template #title>
              <div class="text-center">Profil Pengguna</div>
            </template>
            <template #content>
              <div class="text-center">
                <Avatar icon="pi pi-user" size="xlarge" />
                <div class="mt-3">
                  <div
                    class="
                      grid
                      col-11 col-offset-1
                      lg:col-8 lg:col-offset-2
                      justify-content-center
                    "
                  >
                    <div class="col-12 lg:col-5 text-start text-400">Nama</div>
                    <div class="col-12 lg:col-6 text-start value-form">
                      {{ data.name }}
                    </div>
                    <div class="col-12 lg:col-5 text-start text-400">
                      ID Pengguna
                    </div>
                    <div class="col-12 lg:col-6 text-start value-form">
                      {{ data.username }}
                    </div>
                    <div class="col-12 lg:col-5 text-start text-400">
                      No Kad Pengenalan
                    </div>
                    <div class="col-12 lg:col-6 text-start value-form">
                      {{ data.nokp }}
                    </div>
                    <div class="col-12 lg:col-5 text-start text-400">
                      No Tel Bimbit
                    </div>
                    <div class="col-12 lg:col-6 text-start value-form">
                      {{ data.notelbimbit }}
                    </div>
                    <div class="col-12 lg:col-5 text-start text-400">
                      Emel
                    </div>
                    <div class="col-12 lg:col-6 text-start value-form">
                      {{ data.email }}
                    </div>
                    <div class="col-12 lg:col-5 text-start text-400">
                      Alamat
                    </div>
                    <div class="col-12 lg:col-6 text-start value-form">
                      {{ data.alamat }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
      <div v-else>
        <div
          class="
            flex
            align-items-center
            justify-content-center
            container-height
            fadein
            animation-duration-400
          "
        >
          <div class="grid justify-content-center">
            <div class="col-12 mb-4 text-center">
              <i class="pi pi-discord text-8xl text-400"></i>
            </div>
            <div class="col-12 text-center">
              <span class="text-400">Profil tidak lengkap.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </the-template>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      data: [],
      data_2: [],
      loading: false,
      status: false,
    };
  },
  mounted: function () {
    this.profile();
  },
  methods: {
    profile() {
      this.loading = true;
      axios
        .get("/user/profile/" + localStorage.getItem("nokp"), {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.success == true) {
            this.status = true;
            this.data = response.data.message[0];
            this.data_2 = response.data.message[1];
            this.loading = false;
          } else {
            this.status = false;
            this.loading = false;
          }
        })
        .catch((error) => {
          alert(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.container-height {
  min-height: 70vh;
}

.value-form {
  border: 1px solid #ced4da;
  margin-bottom: 0.5rem;
  border-radius: 6px;
}
</style>
