<template>
  <div class="row text-left">
    <div class="col-12 lg:col-6 paragraph-align">
      <p>
        Para pembida yang dihargai, anda dinasihatkan untuk memahami segala
        terma dan syarat bidaan sebelum membuat sebarang bidaan secara atas
        talian.
      </p>
      <ol class="pr-3">
        <li>Pendaftaran adalah secara atas talian.</li>
        <li>
          Bidaan terbuka kepada semua warganegara berumur 18 tahun ke atas dan
          syarikat/koperasi yang berdaftar di Malaysia.
        </li>
        <li>
          Nilai bidaan hendaklah genap dan penggunaan nilaian sen adalah tidak
          dibenarkan.
        </li>
        <li>
          Waktu tamat bidaan adalah pada jam 12.30 petang (bergantung kepada
          perubahan dari masa ke semasa).
        </li>
        <li>
          Pembida berjaya hendaklah menyelesaikan keseluruhan amaun bidaan dan
          mengambil marhun pada/sebelum jam 4.00 petang.
        </li>
        <li>
          Setiap bidaan atas talian yang telah dihantar tidak boleh diubah. Sila
          pastikan nombor siri dan harga bidaan adalah betul. Pihak kami tidak
          akan bertanggungjawab di atas kesilapan pelanggan mengisi harga
          bidaan.
        </li>
      </ol>
    </div>
    <div class="col-12 lg:col-6 paragraph-align">
      <ol class="pr-3" start="7">
        <li>
          Kaedah pembayaran boleh melalui perbankan atas talian (Online
          Banking/CDM).
        </li>
        <li>
          Sila serahkan bukti pembayaran semasa menuntut marhun bidaan (di
          kaunter atau melalui media elektronik sama ada emel atau Whatsapp).
        </li>
        <li>
          Pembida yang menang di dalam bidaan tetapi gagal melakukan pembayaran
          pada masa yang telah ditetapkan akan disenarai hitamkan.
        </li>
        <li>
          Semua Terma & Syarat diatas adalah tertakluk kepada pindaan dan boleh
          diubah dari masa ke semasa tanpa dimaklumkan kepada pembida.
        </li>
        <li>
          Pengesahan bayaran akan dibuat selepas jam 2petang.
        </li>
      </ol>
    </div>
  </div>
</template>