<template>
  <Galleria
    ref="galleria"
    :value="images"
    v-model:activeIndex="activeIndex"
    :numVisible="5"
    containerStyle="max-width: 640px"
    :containerClass="galleriaClass"
    :showThumbnails="showThumbnails"
    :showItemNavigators="true"
    :showItemNavigatorsOnHover="true"
    :circular="true"
  >
    <template #item="slotProps">
      <div class="grid">
        <img
          :src="'data:image/jpeg;base64, ' + slotProps.item.filename"
          :style="[
            {
              width: !fullScreen ? '100%' : '',
              display: !fullScreen ? 'block' : '',
            },
          ]"
        />
        <!-- <div class="text-lg text-center">{{ slotProps.item.lelongbidmarhun_id }}</div> -->
      </div>
    </template>
    <!-- <template #thumbnail="slotProps">
      <div class="grid grid-nogutter justify-content-center">
        <img
          :src="slotProps.item.thumbnailImageSrc"
          :alt="slotProps.item.alt"
          style="display: block"
        />
      </div>
    </template> -->
    <!-- <template #footer>
      <div class="custom-galleria-footer text-end">
        <Button icon="pi pi-list" @click="onThumbnailButtonClick" />
        <span v-if="images" class="title-container">
          <span>{{ activeIndex + 1 }}/{{ images.length }}</span>
          <span class="title">{{ images[activeIndex].title }}</span>
          <span>{{ images[activeIndex].alt }}</span>
        </span>
        <Button
          :icon="fullScreenIcon"
          @click="toggleFullScreen"
          class="fullscreen-button"
        />
      </div>
    </template> -->
  </Galleria>
</template>

<script>
export default {
  props: {
    gambarMultiple: "",
  },
  data() {
    return {
      images: this.gambarMultiple,
      activeIndex: 0,
      showThumbnails: false,
      fullScreen: false,
    };
  },
  methods: {
    toggleFullScreen() {
      if (this.fullScreen) {
        this.closeFullScreen();
      } else {
        this.openFullScreen();
      }
    },
    onFullScreenChange() {
      this.fullScreen = !this.fullScreen;
    },
    openFullScreen() {
      let elem = this.$refs.galleria.$el;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    },
    closeFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    bindDocumentListeners() {
      document.addEventListener("fullscreenchange", this.onFullScreenChange);
      document.addEventListener("mozfullscreenchange", this.onFullScreenChange);
      document.addEventListener(
        "webkitfullscreenchange",
        this.onFullScreenChange
      );
      document.addEventListener("msfullscreenchange", this.onFullScreenChange);
    },
    unbindDocumentListeners() {
      document.removeEventListener("fullscreenchange", this.onFullScreenChange);
      document.removeEventListener(
        "mozfullscreenchange",
        this.onFullScreenChange
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        this.onFullScreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        this.onFullScreenChange
      );
    },
  },
  computed: {
    // dataUrl() {
    //   this.image = this.gambarMain.filename;
    //   return "data:image/jpeg;base64," + this.image;
    // },
    galleriaClass() {
      return ["custom-galleria", { fullscreen: this.fullScreen }];
    },
    fullScreenIcon() {
      return `pi ${
        this.fullScreen ? "pi-window-minimize" : "pi-window-maximize"
      }`;
    },
  },
};
</script>

<style scoped>
.product-image {
  width: 450px;
}

@media screen and (max-width: 1199px) {
  .product-image {
    width: 280px;
  }
}

@media screen and (max-width: 992px) {
  .product-image {
    width: 250px;
  }
}
</style>