<template>
  <div v-if="isLoading" class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
  <div v-else style="overflow-x: auto;" class="images" v-viewer>
    <img :src="dataUrl" class="photo-style">
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    norujukan: "",
    filemarhun_id: "",
  },
  data() {
    return {
      image: "",
      isLoading: false,
      componentKey: 0,
    };
  },
  computed: {
    dataUrl() {
      return "data:image/png;base64, " + this.image;
    },
  },
  mounted: function () {
    this.getPhotoMarhun();
  },
  methods: {
    getPhotoMarhun() {
      this.isLoading = true;
      axios
        .get("/photoMarhun", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            norujukan: this.norujukan,
            filemarhun_id: this.filemarhun_id,
          },
        })
        .then((response) => {
          this.isLoading = false;
          this.image = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.product-image {
  min-height: 200px;
  min-width: 200px;
  max-height: 350px;
  max-width: 350px;
}

.photo-style {
  width: 298px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
</style>