<template>
  <div v-if="isLoading" class="fadein animation-duration-400">
    <div
      class="flex align-items-center justify-content-center container-height"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <div
    v-else
    style="min-height: 70vh"
    class="container fadein animation-duration-400"
  >
    <div v-if="isSuccess" class="row">
      <div class="col-12 lg:col-9">
        <Card>
          <template #title>
            <div class="font-normal">Senarai Troli</div>
          </template>
          <template #content>
            <Accordion :multiple="true">
              <AccordionTab v-for="item in listItems" v-bind:key="item">
                <template #header>
                  <div class="flex justify-content-between w-100">
                    <div class="align-self-center" style="margin: 5px">
                      {{ item.caw_name }}
                    </div>
                    <div class="style-info">
                      Jumlah RM{{
                        item.total_bidaan
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                      }}
                    </div>
                  </div>
                </template>
                <div class="overflow-x-auto">
                  <table class="table text-center table-bordered table-hover">
                    <tbody>
                      <tr class="table-light">
                        <td>Bil</td>
                        <td style="min-width: 180px">No Rujukan</td>
                        <td>Berat</td>
                        <td style="min-width: 110px">Harga Asas</td>
                        <td style="min-width: 110px">Harga Bida</td>
                        <td style="min-width: 170px">Tambahan Bidaan</td>
                        <td>Tindakan</td>
                      </tr>
                      <tr
                        v-for="(surat, index) in item.lelongbid"
                        :key="surat.id"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#marhunModal"
                            v-on:click="openModal(surat)"
                          >
                            {{ surat.norujukan }}
                          </a>
                        </td>
                        <td>{{ surat.berat }}g</td>
                        <td>
                          RM{{
                            surat.hargaasas
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                          }}
                        </td>
                        <td>
                          RM{{
                            surat.hargabida
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                          }}
                        </td>
                        <th>
                          RM{{
                            surat.tambahanbidaan
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                          }}
                        </th>
                        <td>
                          <Button
                            icon="pi pi-trash"
                            data-bs-toggle="modal"
                            data-bs-target="#deleteModal"
                            v-on:click="openModal(surat)"
                            class="p-button-text p-button-danger"
                          />
                          <Button
                            icon="pi pi-file-edit"
                            data-bs-toggle="modal"
                            data-bs-target="#editModal"
                            v-on:click="openModal(surat)"
                            class="p-button-warning p-button-text"
                          />
                        </td>
                      </tr>
                      <tr style="text-transform: uppercase">
                        <td colspan="2">Jumlah</td>
                        <td>{{ item.total_berat }}<span class="text-lowercase">g</span></td>
                        <td class="table-light"></td>
                        <td>
                          RM{{
                            item.total_bidaan
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                          }}
                        </td>
                        <td colspan="2" class="table-light"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </AccordionTab>
            </Accordion>
          </template>
        </Card>
      </div>
      <div class="col-12 lg:col-3">
        <Card>
          <template #title>
            <div class="font-normal">Ringkasan Troli</div>
          </template>
          <template #content>
            <div>
              <i class="pi pi-paperclip text-primary"
                ><span class="ml-1">Jumlah Surat</span></i
              >
              <p>{{ masterTotalSurat }}</p>
            </div>
            <div>
              <i class="pi pi-wallet text-primary"
                ><span class="ml-1">Jumlah Kesuluruhan Bidaan</span></i
              >

              <p class="font-bold text-xl">
                RM{{
                  masterTotalBidaan
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                }}
              </p>
            </div>
            <Button
              label="Teruskan"
              class="p-button-sm w-full"
              v-on:click="openModalNew()"
            />
            <div class="bounce-up-down">
              <div class="info-teruskan-icon">
                <i
                  class="pi pi-arrow-circle-up text-3xl"
                  style="color: #ff5757"
                ></i>
              </div>
              <div class="info-teruskan">
                Sila klik 'Teruskan' jika pasti untuk meneruskan bidaan ini.
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
    <div v-else>
      <div
        class="flex align-items-center justify-content-center container-height"
      >
        <div class="grid justify-content-center">
          <div class="col-12 mb-4 text-center">
            <i class="pi pi-shopping-cart text-8xl text-400"></i>
          </div>
          <div class="col-12 text-center">
            <span class="text-400">Tiada Data Dalam Troli.</span>
          </div>
          <div class="col-12 text-center">
            <span class="text-400"
              >Sila rujuk halaman
              <router-link v-bind:to="{ name: 'list' }">Senarai</router-link>
              untuk tambah item ke troli.</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- MARHUN MODAL -->
    <div
      class="modal fade"
      id="marhunModal"
      tabindex="-1"
      aria-labelledby="marhunModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="marhunModalLabel">
              {{ theSurat.norujukan }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="grid">
              <div
                class="col-12 lg:col-6"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  min-height: 300px;
                  min-width: 300px;
                  max-height: 600px;
                  max-width: 600px;
                "
              >
                <item-master-image-modal
                  v-if="theSurat.filemarhun"
                  v-bind:key="reloadImageComponent"
                  v-bind:norujukan="theSurat.norujukan"
                  v-bind:filemarhun_id="theSurat.filemarhun[0].id"
                ></item-master-image-modal>
                <p v-else style="text-align: center; width: 100%">
                  Image Unavailable
                </p>
              </div>
              <div class="col-12 text-center lg:col-6">
                <div class="overflow-x-auto">
                  <table class="table table-bordered">
                    <tbody>
                      <tr class="table-light">
                        <td>Bil</td>
                        <td style="min-width: 115px">Jenis Marhun</td>
                        <td style="min-width: 42px">Karat</td>
                        <td style="min-width: 42px">Berat</td>
                        <td style="min-width: 102px">Harga Asas</td>
                      </tr>
                      <tr
                        v-for="(data, index) in theSurat.lelongbidmarhun"
                        v-bind:key="data"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ data.marhun }}</td>
                        <td>{{ data.karat }}</td>
                        <td>{{ data.berat }}g</td>
                        <td>
                          RM{{
                            data.hargaasas
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                          }}
                        </td>
                      </tr>
                      <tr style="text-transform: uppercase">
                        <td colspan="3">Jumlah</td>
                        <td>{{ theSurat.berat }}g</td>
                        <td>
                          RM{{
                            theSurat.hargaasas
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row justify-content-center">
                  <div class="col-6">
                    <label class="col-form-label">Jumlah Harga Bida</label>
                  </div>
                  <div class="col-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text">RM</span>
                      <span class="form-control font-bold input-group-text">{{
                        theSurat.hargabida
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                      }}</span>
                    </div>
                  </div>
                </div>
                <Button
                  icon="pi pi-trash"
                  label="Padam Surat"
                  data-bs-toggle="modal"
                  iconPos="right"
                  data-bs-target="#deleteModal"
                  v-on:click="openModal(theSurat)"
                  class="p-button-text p-button-danger"
                />
                <Button
                  icon="pi pi-file-edit"
                  label="Ubah Bidaan"
                  data-bs-toggle="modal"
                  iconPos="right"
                  data-bs-target="#editModal"
                  v-on:click="openModal(theSurat)"
                  class="p-button-warning p-button-text"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <Button
              class="p-button-sm"
              label="Kembali"
              data-bs-dismiss="modal"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- EDIT MODAL -->
    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      aria-labelledby="editModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">
              {{ theSurat.norujukan }}
            </h5>
            <button
              type="button"
              class="btn-close"
              ref="editClose"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="row m-2">
              <div class="col">
                <div class="row justify-content-center">
                  <div class="col-6">
                    <label class="col-form-label text-end"
                      >Jumlah Harga Asas (A)</label
                    >
                  </div>
                  <div class="col-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text">RM</span>
                      <div
                        style="background-color: #e9ecef; padding-bottom: 0px"
                        class="form-control text-start justify-content-start"
                        disabled
                      >
                        {{
                          theSurat.hargaasas
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-6">
                    <label class="col-form-label text-end"
                      >Jumlah Harga Bida (B)</label
                    >
                  </div>
                  <div class="col-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text">RM</span>
                      <input
                        type="text"
                        class="form-control"
                        v-model="jumlahBidaan"
                        v-on:keyup="calcDiff()"
                      />
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center mb-3">
                  <div class="col-6">
                    <label class="col-form-label text-end">Beza (B-A)</label>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span class="input-group-text">RM</span>
                      <span class="form-control input-group-text">
                        {{
                          tambahanBidaan
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                        }}
                      </span>
                    </div>
                  </div>
                  <div class="col-12" v-if="v$.tambahanBidaan.$error">
                    <div style="font-size: 15px" class="fst-italic text-danger">
                      <div class="text-end">
                        *Minimum beza harga adalah RM1.
                      </div>
                      <div class="text-end">
                        *Gandaan ringgit sahaja dibenarkan.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <Button
              class="p-button-sm p-button-danger m-1"
              label="Batal"
              data-bs-dismiss="modal"
            />
            <Button
              class="p-button-sm p-button-success m-1"
              icon="pi pi-file-edit"
              iconPos="right"
              label="Ubah"
              :loading="isLoadingUbah"
              @click="updateItem(theSurat)"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- DELETE MODAL -->
    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content text-center">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              ref="deleteClose"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <h1><i class="bi bi-exclamation-triangle text-warning"></i></h1>
            <h5>ADAKAH ANDA PASTI?</h5>
            <p>Padam item dalam troli.</p>

            <div class="overflow-x-auto">
              <table class="table table-bordered">
                <tbody>
                  <tr class="bg-light">
                    <td style="min-width: 220px">SAG</td>
                    <td style="min-width: 170px">Harga Bida</td>
                  </tr>
                  <tr>
                    <td>{{ theSurat.norujukan }}</td>
                    <th>
                      RM{{
                        theSurat.hargabida
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                      }}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="text-danger text-sm mt-3">
              <i>*Tindakan ini tidak dapat dikembalikan</i>
            </p>
          </div>
          <div class="modal-footer">
            <Button
              class="p-button-sm p-button-danger mr-2"
              label="Batal"
              data-bs-dismiss="modal"
            />
            <Button
              class="p-button-sm p-button-success"
              icon="pi pi-trash"
              iconPos="right"
              label="Padam"
              :loading="isLoadingPadam"
              @click="deleteItem(theSurat)"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- DAFTAR BIDAAN MODAL -->
    <div
      class="modal fade"
      id="bidModal"
      tabindex="-1"
      aria-labelledby="bidModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content text-center">
          <div class="modal-header">
            <h5 class="modal-title" id="bidModalLabel">Daftar Bidaan</h5>
            <button
              type="button"
              class="btn-close"
              ref="daftarBidaanClose"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <h1><i class="bi bi-exclamation-triangle text-warning"></i></h1>
            <h3>ADAKAH ANDA PASTI?</h3>
            <div class="row">
              <div class="col">
                <input type="checkbox" v-model="akadLelong" name="akad" />
                Saya bersetuju untuk membayar semua surat yang dimenang sebelum
                jam 4:00pm hari ini.
                <p>Jika tidak selesai, saya akan dibanned.</p>
              </div>
              <div
                class="alert alert-danger"
                role="alert"
                v-if="v$.akadLelong.$error"
              >
                Sila tick kotak akad lelong sebelum meneruskan.
              </div>
            </div>
            <p class="text-danger">
              <i>*Bidaan tidak dapat diubah selepas dihantar.</i>
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-danger me-2"
              data-bs-dismiss="modal"
            >
              Tidak
            </button>
            <button
              type="submit"
              class="btn btn-success"
              v-on:click="bidderSelectBidItem()"
            >
              Ya
            </button>
            <p
              class="fst-italic mt-3 fw-lighter"
              style="text-align: right; font-size: small"
            ></p>
          </div>
        </div>
      </div>
    </div>
    <!-- DAFTAR BIDAAN MODAL - PRIMEVUE -->
    <Dialog
      header="Daftar Bidaan"
      v-model:visible="displayModal"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="text-center">
        <h1><i class="bi bi-exclamation-triangle text-warning"></i></h1>
        <h3>ADAKAH ANDA PASTI?</h3>
      </div>
      <div class="text-center">
        <div class="overflow-x-auto">
          <table class="table table-bordered">
            <tbody>
              <tr class="bg-light">
                <td style="min-width: 220px">Jumlah Surat</td>
                <td style="min-width: 170px">Jumlah Kesuluruhan Bidaan</td>
              </tr>
              <tr>
                <td>{{ masterTotalSurat }}</td>
                <th>
                  RM{{
                    masterTotalBidaan
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                  }}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-3 mb-1">
          <label>
            <input type="checkbox" v-model="akadLelong" />
            Saya bersetuju untuk membayar kesemua bidaan yang menang.
          </label>
        </div>
        <InlineMessage v-if="v$.akadLelong.$error"
          >Anda mestilah bersetuju dengan akad lelong.</InlineMessage
        >
      </div>
      <div class="text-center mt-5">
        <p class="text-danger">
          <i>*Bidaan tidak dapat diubah selepas dihantar.</i>
        </p>
      </div>
      <template #footer>
        <Button
          label="Tidak"
          icon="pi pi-times"
          iconPos="right"
          @click="closeModalNew()"
          class="p-button-text"
        />
        <Button
          label="Ya"
          icon="pi pi-check"
          iconPos="right"
          :loading="isLoadingDaftar"
          v-on:click="bidderSelectBidItem()"
        />
      </template>
    </Dialog>
    <!-- GAGAL DAFTAR BIDAAN MODAL -->
    <div
      class="modal fade"
      id="gagalDaftarBidaanModal"
      tabindex="-1"
      aria-labelledby="gagalDaftarBidaanModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="gagalDaftarBidaanModalLabel"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <h1><i class="bi bi-x-circle text-danger"></i></h1>
            <h5>Gagal Daftar Bidaan.</h5>
            <p class="mb-0">Tiada data dalam troli.</p>
            <p>Sila tambah surat ke dalam troli anda.</p>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Kembali
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required, integer, numeric, minValue } from "@vuelidate/validators";
import ItemMasterImageModal from "../list/ItemMasterImageModal.vue";

export default {
  emits: ["reloadComponent"],
  components: {
    "item-master-image-modal": ItemMasterImageModal,
  },
  data() {
    return {
      v$: useVuelidate(),
      displayModal: false,
      isLoadingUbah: false,
      isLoadingPadam: false,
      isLoadingDaftar: false,
      isLoading: false,
      isSuccess: false,
      masterTotalBidaan: Number,
      masterTotalSurat: integer,
      listItems: "",
      theSurat: "",
      tambahanBidaan: "",
      reloadImageComponent: 0,
      jumlahBidaan: "",
      akadLelong: null,
      hargaAsas: "",
      formDelete: {
        userId: "",
        lelongBidId: "",
      },
      formEdit: {
        userId: localStorage.getItem("id"),
        lelongBidId: "",
        hargabida: "",
      },
      formRegisterBid: {
        lelong_id: localStorage.getItem("lelong_id"),
        user_id: localStorage.getItem("id"),
        name: localStorage.getItem("name"),
      },
    };
  },
  validations() {
    return {
      tambahanBidaan: { required, numeric, integer, minValue: minValue(1) },
      akadLelong: { required },
    };
  },
  mounted: function () {
    this.listCart();
  },
  methods: {
    openModalNew() {
      this.displayModal = true;
      this.akadLelong = null;
    },
    closeModalNew() {
      this.displayModal = false;
    },
    listCart() {
      this.isLoading = true;
      axios
        .get("/cartByCawangan/" + localStorage.getItem("id"), {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          // console.log("listCart", response);
          this.isLoading = false;
          if (response.data.success == true) {
            this.isSuccess = true;
            this.listItems = response.data.cart;
            this.masterTotalSurat = response.data.master_total_surat;
            this.masterTotalBidaan = response.data.master_total_bidaan;
          } else if (response.data.success == false) {
            this.isSuccess = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openModal(value) {
      this.theSurat = value;
      this.tambahanBidaan = value.hargabida - value.hargaasas;
      this.jumlahBidaan = parseFloat(value.hargabida);
      this.hargaAsas = value.hargaasas;
      this.reloadImageComponent += 1;
    },
    deleteItem(value) {
      this.isLoadingPadam = true;
      this.formDelete.userId = localStorage.getItem("id");
      this.formDelete.lelongBidId = value.id;
      axios
        .post("/deleteItem", this.formDelete, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.isLoadingPadam = false;
          if (response.data.success == true) {
            this.$refs.deleteClose.click();
            this.$swal.fire({
              icon: "success",
              title: "Surat Berjaya Dipadam",
              confirmButtonColor: "#344871",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
            this.$emit("reloadComponent");
          } else if (response.data.success == false) {
            console.log(response);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Gagal! Sila refresh page semula.",
              animation: false,
              position: "bottom",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
            this.$emit("reloadComponent");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    calcTotal() {
      this.jumlahBidaan =
        parseFloat(this.hargaAsas) + parseFloat(this.tambahanBidaan);
    },
    calcDiff() {
      this.tambahanBidaan =
        parseFloat(this.jumlahBidaan) - parseFloat(this.hargaAsas);
    },
    updateItem(value) {
      this.v$.$validate();
      if (!this.v$.tambahanBidaan.$error) {
        this.isLoadingUbah = true;
        this.formEdit.lelongBidId = value.id;
        this.formEdit.hargabida = this.jumlahBidaan;
        axios
          .post("/updateItem", this.formEdit, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            // console.log("updateItem", response);
            this.isLoadingUbah = false;
            // this.isLoading = true;
            this.$refs.editClose.click();
            this.$swal.fire({
              icon: "success",
              title: "Bidaan Berjaya Diubah",
              confirmButtonColor: "#344871",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
            // this.isLoading = false;
            this.$emit("reloadComponent");
          })
          .catch((error) => {
            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Gagal! Sila refresh page semula.",
              animation: false,
              position: "bottom",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
            this.isLoadingUbah = false;
          });
      } else {
        console.log("masuk error");
      }
    },
    bidderSelectBidItem() {
      if (this.isSuccess == true) {
        this.v$.$validate();
        if (!this.v$.akadLelong.$error) {
          // this.loading = true;
          this.isLoadingDaftar = true;
          axios
            .post("/bidder/biditem", this.formRegisterBid, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((response) => {
              // console.log("bidderSelectBidItem", response);
              if (response.data.success == "success") {
                this.isLoadingDaftar = false;
                this.$refs.daftarBidaanClose.click();
                this.$swal.fire({
                  icon: "success",
                  title: "Bidaan Berjaya Didaftarkan",
                  text: "Sila ke halaman rekod untuk senarai rekod bidaan.",
                  confirmButtonColor: "#344871",
                  confirmButtonText: "OK",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
                // this.loading = false;
                this.$emit("reloadComponent");
              } else {
                this.isLoadingDaftar = false;
                console.log(response);
                this.$swal.fire({
                  toast: true,
                  icon: "error",
                  title: "Gagal! Sila refresh page semula.",
                  animation: false,
                  position: "bottom",
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                });
              }
            })
            .catch((error) => {
              // this.$refs.daftarBidaanClose.click();
              // this.loading = false;
              console.log(error);
              this.$swal.fire({
                toast: true,
                icon: "error",
                title: "Gagal! Sila refresh page semula.",
                animation: false,
                position: "bottom",
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
              });
            });
        } else {
        }
      } else {
        this.$refs.daftarBidaanClose.click();
        this.$swal.fire({
          icon: "error",
          title: "Gagal Daftar Bidaan",
          text: "Sila tambah item ke dalam troli.",
          confirmButtonColor: "#344871",
          confirmButtonText: "OK",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
    },
  },
};
</script>

<style scoped>
.container-height {
  min-height: 70vh;
}

.info-teruskan {
  background: #ffe7e6;
  border: solid #ff5757;
  border-width: 0px;
  color: #ff5757;
  padding: 0.4rem 0.9rem;
  border-radius: 6px;
  text-align: center;
}

.info-teruskan-icon {
  display: flex;
  justify-content: center;
  padding: 0.4rem 0.9rem 0 0.9rem;
  margin-bottom: 15px;
}

.icon-color {
  color: #ff5757;
}
</style>