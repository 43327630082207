<template>
  <the-template>
    <base-card>
      <h5>Resit Bayaran</h5>
      <h5>Tarikh Lelong: 12/12/2022</h5>
      <div class="d-flex">
        <router-link
          v-bind:to="{ name: 'result' }"
          class="btn btn-secondary m-2 me-0"
          >Kembali</router-link
        >
      </div>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Receipt Id</th>
            <th scope="col">Jumlah</th>
            <th scope="col">Resit</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td>bkhsjd12</td>
            <td>RM121212.00</td>
            <td>
              <a href="#" class="btn btn-outline-primary">
                Muat Turun <i class="bi bi-download"></i
              ></a>
            </td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>xsfsj901</td>
            <td>RM100.00</td>
            <td>
              <a href="#" class="btn btn-outline-primary">
                Muat Turun <i class="bi bi-download"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </base-card>
  </the-template>
</template>