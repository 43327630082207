<template>
  <Card style="margin-bottom: 1em">
    <template #title>
      <div class="flex justify-content-center flex-wrap card-container">
        <div class="font-normal">Harga Emas Lelongan</div>
        <Button
          class="button-standard p-button-rounded p-button-outlined"
          icon="pi pi-refresh"
          @click="toggleReloadComponent()"
        />
      </div>
    </template>
    <template #content>
      <current-karat-detail v-bind:key="componentKey"></current-karat-detail>
    </template>
  </Card>
</template>

<script>
import CurrentKaratDetail from "./CurrentKaratDetail.vue";

export default {
  components: {
    "current-karat-detail": CurrentKaratDetail,
  },
  data() {
    return {
      componentKey: 0,
    };
  },
  methods: {
    toggleReloadComponent() {
      this.componentKey += 1;
    },
  },
};
</script>

<style scoped>
.button-standard {
  height: 30px !important;
  width: 30px !important;
  margin-top: 3px !important;
}
</style>
