<template>
  <div v-if="isLoading" class="fadein animation-duration-400">
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <div v-else class="fadein animation-duration-400">
    <div class="overflow-x-auto">
      <table
        v-if="responseData"
        class="table table-sm table-bordered table-hover"
      >
        <tbody>
          <tr class="table-light font-bold">
            <th>Bil</th>
            <th style="min-width: 200px">Cawangan Ar-Rahn</th>
            <th style="min-width: 120px" class="text-center">Tarikh</th>
          </tr>
          <tr v-for="(data, index) in response" :key="data.id">
            <td>{{ index + 1 }}</td>
            <td>{{ data.caw_name }}</td>
            <td class="text-center">{{ data.tarikh_lelong }}</td>
          </tr>
        </tbody>
      </table>
      <table v-else class="table table-sm table-bordered table-hover">
        <tbody>
          <tr class="table-light font-bold">
            <td>Bil</td>
            <td>Cawangan Ar-Rahn</td>
            <td>Tarikh</td>
          </tr>
          <tr>
            <td colspan="4" class="text-center text-500">Tiada Data</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useAuthenticationUserStore } from "@/store/modules/authentication";

export default {
  data() {
    return {
      responseData: false,
      response: Array,
      isLoading: false,
    };
  },
  mounted: function () {
    this.liveData();
  },
  methods: {
    liveData() {
      this.isLoading = true;
      axios
        .get("/listcawlelongnext", {
          headers: {
            Authorization: "Bearer " + useAuthenticationUserStore().getToken,
          },
        })
        .then((response) => {
          // console.log("liveData", response);
          if (response.data.success == true) {
            this.responseData = true;
            this.response = response.data.lelong;
            this.isLoading = false;
          } else {
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>