<template>
  <div v-if="isLoading" class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
  <div v-else class="images" v-viewer>
    <img :src="dataUrl" class="photo-style">
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    fileResitId: "",
  },
  data() {
    return {
      image: "",
      isLoading: false,
      componentKey: 0,
    };
  },
  computed: {
    dataUrl() {
      return "data:image/png;base64, " + this.image;
    },
  },
  mounted: function () {
    this.showPhotoResit();
  },
  methods: {
    showPhotoResit() {
      this.isLoading = true;
      axios
        .get("/showPhotoResit", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            fileresit_id: this.fileResitId,
          },
        })
        .then((response) => {
          this.isLoading = false;
          this.image = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.photo-style {
  width: 100px;
  /* border-radius: 8px; */
  cursor: pointer;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
</style>
