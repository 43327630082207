<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style scoped>
/* 

#132a4f //Shades
#1e417b //#######MAIN COLOR - nav element#############
#2f68c5 //Tints - main button
#82a4dc //nav button
#d5e1f3 //background nav:active
#eaf0f9 //background inline message

*/
</style>
