<template>
  <Toast :breakpoints="{ '767px': { width: '100%', right: '0', left: '0' } }" />
  <div class="flex main-container min-h-screen align-items-center justify-content-center">
    <div class="surface-card fadein p-4 shadow-8 border-round w-11 md:w-10 lg:w-8 xl:w-7">
      <div>
        <div class="row">
          <div class="col-md-2 d-flex flex-column justify-content-center align-items-left" style="margin-right: -65px">
            <img class="logo-img1" src="img/e-Rahn_Logo_FA-01.png" alt="erahn-logo" />
          </div>
          <!-- <div class="col-md-2 d-flex flex-column justify-content-center align-items-left">
            <img class="logo-img2" src="img/Logo KoPKB_Final.png" alt="erahn-logo" />
          </div> -->
          <div class="col-md-2 d-flex flex-column justify-content-center align-items-left" style="margin-left: -65px">

          </div>
        </div>
      </div>
      <div class="text-center mb-5">
        <!-- <img class="logo-img" src="img/bidder.png" alt="erahn-logo" /> -->
        <img class="logo-img3 mb-3" src="img/e-rahn-lelong.png" alt="erahn-logo">
        <div class="text-200 text-3xl font-semibold mb-3">Log Masuk</div>
        <span class="text-200 font-medium line-height-3">Belum punyai akaun?</span>
        <router-link v-bind:to="{ name: 'register' }" class="font-medium ml-2 text-blue cursor-pointer">Daftar sekarang!
        </router-link>
      </div>
      <form @submit.prevent="submit">
        <div class="px-0 text-center md:px-8">
          <div class="field grid">
            <!-- <label for="firstname3" class="justify-content-end"
              style="width: 170px">Username</label> -->
            <div class="col">
              <span class="p-input-icon-right">
                <i class="pi pi-user" />
                <InputText class="mb-2" v-model="form.username" type="text" placeholder="ID Pengguna" required>
                </InputText>
              </span>
            </div>
            <InlineMessage class="flex-auto" v-if="v$.form.username.$error">Username diperlukan</InlineMessage>
          </div>
          <div class="field grid">
            <!-- <label for="firstname3"
              class="justify-content-end"
              style="width: 170px">Kata Laluan</label> -->
            <div class="col">
              <span class="p-input-icon-left">
                <i class="pi pi-user" />
                <Password class="mb-2" v-model="form.password" placeholder="Katalaluan" toggleMask :feedback="false" />
              </span>
            </div>
            <InlineMessage class="flex-auto" v-if="v$.form.password.$error">Kata laluan diperlukan</InlineMessage>
          </div>
          <div class="">
            <Button @click="doLogin()" type="submit" class="p-button-sm" label="Log Masuk" icon="pi pi-sign-in"
              :loading="isLoading" iconPos="right"></Button>
          </div>
          

          <div class="flex alin justify-content-center mt-4 text-hover">
            <div class="btn btn-warning btn-sm">
              <terms-and-conditions></terms-and-conditions>
            </div>
            <!-- <a class="btn btn-info btn-sm" href="https://lelong.arrahn.com.my"
              >Pautan Sistem Lelong</a
            > -->
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useAuthenticationUserStore } from "@/store/modules/authentication"

export default {
  name: "Login",
  data() {
    return {
      v$: useVuelidate(),
      form: {
        username: "",
        password: "",
      },
      isLoading: false,
    };
  },
  setup() {
    const authenticationStore = useAuthenticationUserStore()
    return { authenticationStore }
  },
  methods: {
    doLogin() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.isLoading = true;
        axios
          .post("/user/login", this.form)
          .then((response) => {
            // console.log("doLogin", response);
            if (response.data.statusAkaun == 1) {
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("id", response.data.user.id);
              localStorage.setItem("nokp", response.data.user.nokp);
              localStorage.setItem("name", response.data.user.name);
              localStorage.setItem("email", response.data.user.email);
              localStorage.setItem(
                "notelbimbit",
                response.data.user.notelbimbit
              );
              localStorage.setItem("username", response.data.user.username);
              this.authenticationStore.$patch({
                token: response.data.token,
                id: response.data.user.id,
                nokp: response.data.user.nokp,
                name: response.data.user.name,
                email: response.data.user.email,
                notelbimbit: response.data.user.notelbimbit,
                username: response.data.user.username,
              })
              this.$router.push({ name: "home" });
            } else if (response.data.statusAkaun == 0) {
              this.$toast.add({
                severity: "info",
                summary: "Gagal Log Masuk",
                detail: "Tiada akaun dalam database. Sila daftar sekarang.",
                life: 10000,
              });
            } else if (response.data.statusAkaun == 2) {
              this.$toast.add({
                severity: "error",
                summary: "Gagal Log Masuk",
                detail:
                  "Akaun anda telah dibanned. Sila hubungi pihak pengurusan untuk maklumat lebih lanjut.",
                life: 10000,
              });
            } else if (response.data.statusAkaun == 3) {
              this.$toast.add({
                severity: "error",
                summary: "Gagal Log Masuk",
                detail:
                  "Account rejected. Please contact administrator for further information.",
                life: 10000,
              });
            } else if (response.data.statusAkaun == 4) {
              this.$toast.add({
                severity: "info",
                summary: "Gagal Log Masuk",
                detail: "Registration in progress,status wait for approval",
                life: 10000,
              });
            } else if (response.data.statusAkaun == 5) {
              this.$toast.add({
                severity: "error",
                summary: "Gagal Log Masuk",
                detail: "Kata laluan salah. Sila semak semula.",
                life: 10000,
              });
              console.log(response);
            } else if (response.data.statusAkaun == 6) {
              this.$toast.add({
                severity: "warn",
                summary: "Akaun ini didaftar menggunakan id staff.",
                detail:
                  "Sila log masuk menggunakan url https://adminlelong.erahn.com.my ",
                life: 10000,
              });
            }
            this.isLoading = false;
          })
          .catch((response) => {
            // alert(response.message);
            console.log(response);
            this.isLoading = false;
          });
      }
    },
  },
  validations() {
    return {
      form: {
        username: { required },
        password: { required },
      },
    };
  },
};
</script>

<style scoped>
.main-container {
  /* background: linear-gradient(
    135deg,
    rgba(139, 198, 236, 0.3) 0%,
    #9599e2 100%
  ),
  url("/src/assets/background_arrahn.jpg"); */
  background-image: url("/src/assets/background-lelong.png");
  background-color: #fec234;
  background-position: center;
  background-size: cover;
}

.content-bg {
  background: #fff9f9eb !important;
}

.logo-img {
  color: white;
  width: 180px;
  height: 150px;
}

.logo-img1 {
  width: 50px;
  height: 50px;
}

.logo-img2 {
  width: 50px;
  height: 60px;
}

.logo-img3 {
  width: 150px;
  height: 30px;
}

/* .border-round{
  border-radius:50px;
} */
</style>
