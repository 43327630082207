<template>
  <div
    v-if="loading"
    style="min-height: 70vh; display: grid; place-items: center"
  >
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div
    v-else
    style="min-height: 70vh"
    class="container fadein animation-duration-400"
  >
    <div v-if="isSuccess">
      <Card>
        <template #title>
          <div class="grid justify-content-between">
            <div class="font-normal">Bidaan Yang Berjaya</div>
            <div class="text-sm align-self-center fst-italic text-danger">
              *Pembayaran hendaklah diselesaikan SEGERA dan barang lelongan
              mesti DITUNTUT di cawangan terlibat SEBELUM jam 4.00 petang hari lelongan.
            </div>
          </div>
        </template>
        <template #content>
          <div class="overflow-x-auto">
            <table class="table table-bordered text-center table-hover">
              <tbody>
                <tr class="table-light font-bold">
                  <td>Bil</td>
                  <td style="min-width: 115px">Tarikh</td>
                  <td style="min-width: 100px">Jumlah</td>
                  <td style="min-width: 145px">Status Bayaran</td>
                  <!-- <td style="min-width: 150px">Penyata</td> -->
                </tr>
                <tr v-for="(data, index) in lelong" v-bind:key="data">
                  <td>{{ index + 1 }}</td>
                  <td v-on:click="selectDate(data)">
                    <span class="text-primary underline cursor-pointer">{{
                      data.tarikh_lelong
                    }}</span>
                  </td>
                  <td>
                    RM{{
                      data.jumlah
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                    }}
                  </td>
                  <td v-if="data.status == 1">
                    <Tag
                      value="Selesai"
                      icon="pi pi-check"
                      class="p-tag-rounded"
                      severity="success"
                    ></Tag>
                  </td>
                  <td v-else-if="data.status == 2">
                      <Tag
                        value="Tunggu Pengesahan"
                        icon="pi pi-stopwatch"
                        class="p-tag-rounded"
                        severity="warning"
                      ></Tag>
                  </td>
                  <td v-else-if="data.status == null">
                    <div class="blink">
                      <Tag
                        value="Belum Selesai"
                        icon="pi pi-times"
                        class="p-tag-rounded"
                        severity="danger"
                      ></Tag>
                    </div>
                  </td>
                  <!-- <td>
                    <span class="text-primary underline cursor-pointer"
                      >Muat Turun <i class="pi pi-cloud-download"></i>
                    </span>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </Card>
    </div>
    <div v-else>
      <div
        class="flex align-items-center justify-content-center container-height"
      >
        <div class="grid justify-content-center">
          <div class="col-12 mb-4 text-center">
            <i class="pi pi-info-circle text-8xl text-400"></i>
          </div>
          <div class="col-12 text-center">
            <span class="text-400">Tiada Data Dalam Keputusan.</span>
          </div>
          <div class="col-12 text-center">
            <span class="text-400"
              >Sila rujuk halaman
              <router-link v-bind:to="{ name: 'home' }">Utama</router-link>
              untuk jadual lelongan.</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ResultDetail from "./ResultDetail.vue";

export default {
  components: {
    "result-detail": ResultDetail,
  },
  data() {
    return {
      lelong: "",
      isSuccess: false,
      loading: false,
    };
  },
  mounted: function () {
    this.getLelong();
  },
  methods: {
    getLelong() {
      this.loading = true;
      axios
        .get("/resultLelong", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            user_id: localStorage.getItem("id"),
          },
        })

        .then((response) => {
          this.loading = false;
          // console.log("getLelong", response);
          if (response.data.success === true) {
            this.isSuccess = true;
            this.lelong = response.data.lelong;
          } else {
            this.isSuccess = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectDate(value) {
      this.$router.push({
        name: "detail",
        params: { lelongId: value.id, statusPayment: value.status },
      });
    },
  },
};
</script>

<style scoped>
.container-height {
  min-height: 70vh;
}
</style>