<template>
  <h5>Finalise</h5>
  <p>Cawangan: Ar-Rahn Kota Bharu 2</p>
  <table class="table table-bordered">
    <tbody>
      <tr v-for="data in finalSurat" v-bind:key="data">
        <td>{{ data.norujukan }}</td>
        <td>RM{{ data.total_payment }}</td>
      </tr>
    </tbody>
  </table>
  <p>Jumlah Dipilih: RM{{ totalHarga }}</p>
  <div class="d-flex justify-content-between">
    <button class="btn btn-secondary" v-on:click="($emit('back-is-click', false))"
      >Kembali</button
    >
    <button
      ref="backButton"
      data-bs-toggle="modal"
      data-bs-target="#payModal"
      class="btn btn-primary"
    >
      Teruskan
    </button>
  </div>
  <div
    class="modal fade"
    id="payModal"
    tabindex="-1"
    aria-labelledby="payModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="payModalLabel">Bayar Bidaan</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <h1><i class="bi bi-exclamation-triangle text-warning"></i></h1>
          <p>ADAKAH ANDA PASTI?</p>
          <h4 class="text-danger">
            <i
              >Fi pengurusan RM1 akan dikenakan untuk setiap kali bayaran
              melalui Billplz.</i
            >
          </h4>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
            Tidak
          </button>
          <button
            type="button"
            v-on:click="doPayment()"
            class="btn btn-success"
          >
            Ya
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  emits: ["back-is-click"],
  props: {
    totalHarga: "",
    finalSurat: "",
  },
  data() {
    return {
      // changeProp: finalSurat,
      form: {
        email: localStorage.getItem("email"),
        notel: localStorage.getItem("notelbimbit"),
        name: localStorage.getItem("name"),
        user_id: localStorage.getItem("id"),
        lelong_id: localStorage.getItem("lelong_id"),
        invoice_id: "",
        invoice_no: "",
        total: this.totalHarga,
        // sag: [
        //   {
        //     norujukan: "APM211121-0015",
        //     kodcaw: "APM",
        //     total_payment: 1329,
        //   },
        //   {
        //     norujukan: "APM211120-0018",
        //     kodcaw: "APM",
        //     total_payment: 1443,
        //   },
        // ],
        sag: '',
      },
    };
  },
  mounted() {
    this.getInvoice();
  },
  methods: {
    getInvoice() {
      axios
        .get("/invoice", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            user_id: localStorage.getItem("id"),
            lelong_id: localStorage.getItem("lelong_id"),
          },
        })
        .then((response) => {
          if (response.data.success == true) {
            this.form.invoice_id = response.data.invoice.id;
            this.form.invoice_no = response.data.invoice.invoice_no;
            
            let convertData = JSON.parse(JSON.stringify(this.finalSurat));
            this.form.sag = convertData;
          } else if (response.data.success == false) {
            alert("No Invoice Id");
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    doPayment() {
      axios
        .post("/bidpayment", this.form)
        .then((response) => {
          console.log(response);
          window.location.href = response.data.message + "?auto_submit=true";
        })
        .catch((response) => {
          alert(response.message);
          console.log(response);
        });
    },
  },
};
</script>