<template>
  <div v-if="isSuccess">
    <div v-if="notPay">
      <Message severity="success" :closable="false">
        <div class="font-bold">
          Tahniah, bidaan anda menang!
          <a class="ml-1 text-blue" @click="toResult()" href="#">
            <span class="underline">Selesaikan pembayaran sekarang.</span>
          </a>
        </div>
      </Message>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      isSuccess: false,
      notPay: false,
    };
  },
  mounted: function () {
    this.getLelong();
  },
  methods: {
    resultStatus() {
      // "/resultStatus/" + localStorage.getItem("id") + "/" + this.lelongId,
    },
    getLelong() {
      this.loading = true;
      axios
        .get("/resultLelong", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            user_id: localStorage.getItem("id"),
          },
        })

        .then((response) => {
          this.loading = false;
          // console.log("getLelong", response);
          if (response.data.success === true) {
            this.isSuccess = true;
            if (response.data.lelong[0].status == null) {
              this.notPay = true;
            } else {
              this.notPay = false;
            }
          } else {
            this.isSuccess = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toResult() {
      this.$router.push({ name: "result" });
    },
  },
};
</script>