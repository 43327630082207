<template>
  <!-- Navbar for laptop screen size (width>992px) -->
  <div class="card sticky-top lg:flex hidden border-none">
    <Toast :breakpoints="{ '992px': { display: 'none' },  }"/>
    <div class="flex justify-content-between px-3 md:px-6 h-3rem flex-wrap main-color">
      <div class="flex align-items-center justify-content-center">
        <div class="company-title" type="button" v-on:click="toHome()">
          <!-- <div class="logo-title">E-Rahn Lelong</div> -->
          <!-- <img class="logo-img" src="img/logo_arrahn.png" alt="arrahn-logo" /> -->
          <img class="logo-img" src="img/e-rahn-lelong.png" alt="arrahn-logo" type="button" v-on:click="toHome()"/>
        </div>
      </div>
      <div class="flex align-items-center justify-content-center">
        <nav class="main-nav">
          <router-link class="nav-button" v-bind:to="{ name: 'home' }"
            >Utama</router-link
          >
          <router-link class="nav-button" v-bind:to="{ name: 'list' }"
            >Senarai</router-link
          >
          <router-link class="nav-button" v-bind:to="{ name: 'cart' }"
            >Troli</router-link
          >
          <router-link class="nav-button" v-bind:to="{ name: 'bid-record' }"
            >Rekod</router-link
          >
          <router-link class="nav-button" v-bind:to="{ name: 'result' }"
            >Keputusan</router-link
          >
          <router-link class="nav-button" v-bind:to="{ name: 'policy' }"
            >Polisi</router-link
          >
        </nav>
      </div>
      <div class="flex align-items-center justify-content-center">
        <div class="profile-title hidden lg:flex">
          <div class="dropdown">
            <div
              class="dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-person-circle me-1"></i>{{ username }}
            </div>
            <ul class="dropdown-menu">
              <li>
                <div
                  type="button"
                  class="dropdown-item"
                  v-on:click="toProfile()"
                >
                  Profil <i class="bi bi-person-workspace"></i>
                </div>
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <div
                  type="button"
                  v-on:click="doLogout()"
                  class="dropdown-item"
                >
                  Log Keluar <i class="bi bi-box-arrow-right"></i>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Navbar for phone, tab screen size (width<992px) -->
  <div class="card sticky-top lg:hidden md:flex border-none">
    <div
      class="
        flex
        justify-content-between
        px-3
        md:px-6
        h-3rem
        flex-wrap
        main-color
      "
    >
      <div class="flex align-items-center justify-content-center">
        <i
          class="pi pi-bars text-white lg:hidden mr-3"
          style="cursor: pointer"
          @click="visibleRight = true"
        ></i>
        <div class="company-title" type="button" v-on:click="toHome()">
          <div class="logo-title">E-Rahn Lelong</div>
          <!-- <img class="logo-img" src="img/logo_arrahn.png" alt="arrahn-logo" /> -->
        </div>
      </div>

      <div class="flex align-items-center justify-content-center">
        <!-- <i
          class="pi pi-shopping-cart text-white lg:hidden"
          style="cursor: pointer"
          @click="visibleRight = true"
        ></i> -->
        <!-- <i
          class="pi pi-shopping-cart mr-1 p-text-primary"
          style="font-size: 1.5rem; color: #eaf0f9"
          v-badge.success="itemInCart"
          v-on:click="toCart()"
          v-if="isSuccessCart"
        ></i> -->
        <i
          class="pi pi-shopping-cart mr-1 p-text-primary"
          style="font-size: 1.5rem; color: #eaf0f9"
          v-on:click="toCart()"
        ></i>
      </div>
    </div>
    <div v-if="lelongOnline != ''">
      <div
        class="
          flex
          align-items-center
          justify-content-center
          notification-mobile
        "
      >
        <i class="blink pi pi-info-circle mr-2"></i> Lelongan akan tamat pada
        {{ lelongOnline }}
      </div>
    </div>
  </div>
  <Sidebar v-model:visible="visibleRight" position="left">
    <div class="grid">
      <div class="col-12">
        <div class="text-center">
          <i class="bi bi-person-circle me-1"></i> {{ username }}
        </div>
      </div>
      <div class="col-12">
        <router-link
          class="main-nav-link main-nav-link-sidebar"
          v-bind:to="{ name: 'home' }"
          >Utama</router-link
        >
      </div>
      <div class="col-12">
        <router-link
          class="main-nav-link main-nav-link-sidebar"
          v-bind:to="{ name: 'list' }"
          >Senarai</router-link
        >
      </div>
      <div class="col-12">
        <router-link
          class="main-nav-link main-nav-link-sidebar"
          v-bind:to="{ name: 'cart' }"
          >Troli</router-link
        >
      </div>
      <div class="col-12">
        <router-link
          class="main-nav-link main-nav-link-sidebar"
          v-bind:to="{ name: 'bid-record' }"
          >Rekod</router-link
        >
      </div>
      <div class="col-12">
        <router-link
          class="main-nav-link main-nav-link-sidebar"
          v-bind:to="{ name: 'result' }"
          >Keputusan</router-link
        >
      </div>
      <div class="col-12">
        <router-link
          class="main-nav-link main-nav-link-sidebar"
          v-bind:to="{ name: 'policy' }"
          >Polisi</router-link
        >
      </div>
      <div class="col-12">
        <router-link
          class="main-nav-link main-nav-link-sidebar"
          v-bind:to="{ name: 'profile' }"
          >Profil</router-link
        >
      </div>
      <div class="col-12">
        <!-- <router-link
          class="main-nav-link main-nav-link-sidebar"
          v-bind:to="{ name: 'login' }"
          >Login</router-link
        > -->
      </div>
      <div class="col-12">
        <a
          class="main-nav-link main-nav-link-sidebar"
          v-on:click="doLogout()"
          href="#"
          ><i class="bi bi-box-arrow-right me-1"></i> Log Keluar</a
        >
      </div>
    </div>
  </Sidebar>
</template>

<script>
import axios from "axios";
import { useAuthenticationUserStore } from "@/store/modules/authentication"

export default {
  data() {
    return {
      username: localStorage.getItem("username"),
      visibleRight: false,
      lelongOnline: "",
      isSuccessCart: false,
      itemInCart: 0,
    };
  },
  setup() {
    const authenticationStore = useAuthenticationUserStore()

    return { authenticationStore }
  },
  mounted: function () {
    this.getLelongOnline();
    // this.getItemInCart();
  },
  methods: {
    doLogout() {
      axios
        .post(
          "/user/logout",
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          localStorage.clear();
          this.authenticationStore.$reset();
          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          console.log(error);
        });
      localStorage.clear();
      this.$router.push({ name: "login" });
    },
    toHome() {
      this.$router.push({ name: "home" });
    },
    toProfile() {
      this.$router.push({ name: "profile" });
    },
    toCart() {
      this.$router.push({ name: "cart" });
    },
    getLelongOnline() {
      axios
        .get("/lelongOnline", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })

        .then((response) => {
          // console.log('getLelongOnline', response);
          if (response.data.success === true) {
            this.lelongOnline = response.data.lelong.masatamat_lelong;
            this.$toast.add({
              severity: "info",
              summary: "Informasi",
              detail: "Lelongan akan tamat pada " + this.lelongOnline,
            });
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getItemInCart() {
      this.loading = true;
      axios
        .get("/itemInCart", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            user_id: localStorage.getItem("id"),
          },
        })

        .then((response) => {
          // console.log('getItemInCart', response);
          if (response.data.success === true) {
            this.isSuccessCart = true;
            this.itemInCart = response.data.cart;
          } else {
            this.isSuccessCart = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
/* 

========== 01 TYPOGRAPHY ==========
-Font sizes(px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

========== 02 COLORS ==========
-Primary: #1d3461
-Shades: #142444
-Tints: #bbc2d0 #d2d6df
-Accents:
-Greys: 
#555
#333

-Tints KIV: #617190
-Accents KIV: #E9C439

========== 07 WHITESPACE ==========

-Spacing system(px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

*/

/* .nav-link:hover {
  color: blue !important;
}

router-link:hover {
  color: blue;
} */
.notification-mobile {
  background: #e9e9ff;
  color: #696cff;
  height: 2.5rem;
  padding: 25px 10px;
}
.main-color {
  background-color: #0D908B;
}

.border-none {
  border: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0D908B;

  height: 3.2rem;
  padding: 0 6.4rem;
}

.company-title {
  display: flex;
  text-decoration: none;
}

.logo-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eaf0f9;
  font-size: 1rem;
  font-weight: 700;
}

.logo-img {
  height: 1.6rem;
}

.main-nav-list {
  list-style: none;
  display: flex;
  gap: 3.2rem;
  align-items: center;
  margin: 0;
}

.main-nav-link:link,
.main-nav-link {
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  height: 2.4rem;
  color: #82a4dc;
  font-weight: 500;
  font-size: 1rem;
  transition: all 0.3s;
}

.main-nav-link:hover {
  color: #fff;
}

.main-nav-link-sidebar:hover {
  color: #1e417b !important;
}

.nav-button {
  text-decoration: none;
  margin: 0 1.5rem;
  color: #ffffff;
}

.nav-button:hover {
  color: #eaf0f9;
}

a.router-link-exact-active {
  background: #FAE79A;
  color: #000000;
  border-radius: 5px;
  padding: 7px 20px;
}

a.router-link-exact-active:hover {
  color: #132a4f;
}

.profile-title {
  color: #eaf0f9;
}

.arrow-logo {
  font-size: 0.5rem;
}
</style>