<template>
  <the-template>
    <div v-if="isLoading" class="fadein animation-duration-400">
      <div
        class="flex align-items-center justify-content-center container-height"
      >
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div v-else class="fadein animation-duration-400">
      <div v-if="isSuccess">
        <Card>
          <template #title>
            <div class="font-normal">
              <div class="flex">
                <Button
                  class="
                    p-button-sm
                    button-custom
                    p-button-secondary p-button-outlined
                    align-self-center
                    mr-2
                  "
                  icon="pi pi-arrow-left"
                  v-on:click="clickCancelButton()"
                />
                Pembayaran Bidaan
              </div>
            </div>
            <div class="font-normal text-base">
              Tarikh Lelong : {{ tarikhLelong }}
            </div>
          </template>
          <template #content>
            <select-outlet
              v-bind:namaCawangan="lists"
              v-bind:lelongIdProps="lelongId"
            ></select-outlet>
          </template>
        </Card>
      </div>
      <div v-else>
        <div
          class="
            flex
            align-items-center
            justify-content-center
            container-height
          "
        >
          <div class="grid justify-content-center">
            <div class="col-12 mb-4 text-center">
              <i class="pi pi-book text-8xl text-400"></i>
            </div>
            <div class="col-12 text-center">
              <span class="text-400">Tiada Data.</span>
            </div>
            <div class="col-12 text-center">
              <span class="text-400"
                >Sila rujuk halaman
                <router-link v-bind:to="{ name: 'result' }"
                  >Keputusan</router-link
                >
                untuk maklumat rekod bidaan menang.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <base-card>
      <p>Tarikh Lelong : {{ tarikhLelong }}</p>
      <div v-if="statusComponent == 1">
        <select-outlet
          v-bind:namaCawangan="lists"
          v-bind:lelongIdProps="lelongId"
        ></select-outlet>
      </div>
      
    </base-card> -->
  </the-template>
</template>

<script>
import axios from "axios";
import SelectOutlet from "./SelectOutlet.vue";
// import SelectId from "./SelectId.vue";
// import SelectFinalise from "./SelectFinalise.vue";

export default {
  components: {
    "select-outlet": SelectOutlet,
    // "select-id": SelectId,
    // "select-finalse": SelectFinalise,
  },
  data() {
    return {
      lists: [],
      tarikhLelong: "",
      statusComponent: 1,
      invoice_id: "",
      invoice_no: "",
      user: {
        id: 1,
        caw: "KOta Bbharu",
      },
      lelongId: "",
      isLoading: false,
      isSuccess: false,
    };
  },
  created() {
    this.lelongId = this.$route.params.lelongId;
  },
  mounted: function () {
    this.liveData();
    // this.getInvoice();
  },
  methods: {
    liveData() {
      this.isLoading = true;
      if(this.lelongId != null){
        axios
          .get(
            "/bayarBaki/" +
              localStorage.getItem("id") +
              // "/1" ,
              "/" +
              this.lelongId,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            // console.log("liveData", response);
            this.isLoading = false;
            if (response.data.success == true) {
              this.isSuccess = true;
              this.lists = response.data.data;
              this.tarikhLelong = response.data.tarikh_lelong;
            } else {
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } else {
        this.$router.push({ name: "result" });
      }
    },
    clickCancelButton() {
      this.$router.push({
        name: "detail",
        params: {
          lelongId: this.lelongId,
          statusPayment: null,
        },
      });
    },
    clickBackButton() {
      this.statusComponent -= 1;
    },
    clickNextButton() {
      this.statusComponent += 1;
    },
  },
};
</script>

<style scoped>
.container-height {
  min-height: 70vh;
}
</style>