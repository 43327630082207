<template>
  <the-template>
    <div v-if="isLoading" class="fadein animation-duration-400">
      <div
        class="flex align-items-center justify-content-center container-height"
      >
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="
        flex
        align-items-center
        justify-content-center
        fadein
        animation-duration-400
      "
    >
      <div v-if="isSuccess">
        <div class="row">
          <div class="font-normal header-title">Senarai Barangan Lelongan</div>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon3">Cawangan</span>
            <select class="form-select" @change="pilihCawangan($event)">
              <option value="disabled" selected>Sila Pilih Cawangan</option>
              <option
                v-for="data in listCawangan"
                :key="data.id"
                v-bind:value="data.caw_id"
              >
                {{ data.caw_name }}
              </option>
              <input type="hidden" />
            </select>
          </div>
          <list-item
            v-bind:key="componentKey"
            v-bind:selectedCawangan="selectCawangan"
            v-on:reloadComponent="toggleReloadComponent"
          ></list-item>
        </div>
      </div>
      <div v-else>
        <div
          class="
            flex
            align-items-center
            justify-content-center
            container-height
          "
        >
          <div class="grid justify-content-center">
            <div class="col-12 mb-4 text-center">
              <i class="pi pi-flag text-8xl text-400"></i>
            </div>
            <div class="col-12 text-center">
              <span class="text-400">Tiada Data Senarai.</span>
            </div>
            <div class="col-12 text-center">
              <span class="text-400"
                >Sila rujuk halaman
                <router-link v-bind:to="{ name: 'home' }">Utama</router-link>
                untuk jadual lelongan.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </the-template>
</template>

<script>
import ListItem from "./ListItem.vue";
import axios from "axios";

export default {
  data() {
    return {
      componentKey: 0,
      listCawangan: "",
      selectCawangan: "",
      isSuccess: false,
      isLoading: false,
    };
  },
  components: {
    "list-item": ListItem,
  },
  mounted: function () {
    this.getIdCawangan();
  },
  methods: {
    toggleReloadComponent() {
      this.componentKey += 1;
    },
    pilihCawangan(e) {
      let test = e.target.value;
      this.selectCawangan = test;
      this.componentKey += 1;
    },
    getIdCawangan() {
      this.isLoading = true;
      this.isSuccess = false;
      axios
        .get("/listcawlelongtodaystarted", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          // console.log('getIdCawangan', response);
          if (response.data.success == true) {
            this.isSuccess = true;
            this.listCawangan = response.data.lelong;
            this.isLoading = false;
          } else {
            this.isSuccess = false;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.container-height {
  min-height: 70vh;
}

.header-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
</style>