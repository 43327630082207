<template>
  <div
    v-if="loading"
    class="row fadein animation-duration-400"
    style="
      min-height: 70vh;
      min-width: 90vh;
      display: grid;
      place-items: center;
    "
  >
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div v-else class="fadein animation-duration-400">
    <div v-if="this.status">
      <div class="text-center">
        <Message severity="info" :closable="false"
          >Jumlah Surat Lelongan: {{ numberSag }}
        </Message>
      </div>
      <div class="row justify-content-center">
        <div
          v-for="sag in sags"
          :key="sag.id"
          class="col-10 sm:col-8 md:col-6 lg:col-4 box-style"
        >
          <div class="item-main">
            <div class="image-card">
              <item-master-image
                v-if="sag.filemarhun"
                v-bind:norujukan="sag.norujukan"
                v-bind:filemarhun_id="sag.filemarhun[0].id"
                style="width: 100%;"
              ></item-master-image>
              <div v-else class="text-secondary">
                Image Unavailable
              </div>
            </div>
            <div class="card-arrahnbid">
              <div class="card-content-arrahnbid">
                <div>
                  <div class="product_details">
                    <div class="grid text-center">
                      <div class="col-12 pb-0 text-primary">
                        {{ sag.norujukan }}
                      </div>
                      <div class="col-12 pt-0">{{ sag.berat }}g</div>
                    </div>
                    <div class="flex justify-content-between">
                      <div class="text-2xl align-self-center font-bold">
                        RM{{
                          sag.hargaasas
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                        }}
                      </div>
                      <Button
                        data-bs-toggle="modal"
                        data-bs-target="#bidaModal"
                        @click="clickModal(sag)"
                        icon="pi pi-plus p-button-sm"
                        class="p-button-rounded p-custom-button"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div
          class="
            flex
            align-items-center
            justify-content-center
            container-height
          "
        >
          <div class="grid bounce-up-down justify-content-center">
            <div class="col-12 mb-4 text-center">
              <i class="pi pi-arrow-up text-8xl text-400"></i>
            </div>
            <div class="col-12 text-center">
              <span class="text-400"
                >Sila isi ruangan pilih cawangan di atas.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ScrollTop :threshold="200"/>
  <!-- modal marhun -->
  <div
    class="modal fade"
    id="bidaModal"
    tabindex="-1"
    aria-labelledby="bidaModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="bidaModalLabel">
            {{ norujukan.norujukan }}
          </h5>
          <button
            type="button"
            class="btn-close"
            ref="tambahClose"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body">
          <div class="grid">
            <div class="col-12 lg:col-6 style-photo-div p-2">
              <!-- <item-multiple-image
                v-if="filemarhun"
                v-bind:gambarMultiple="filemarhun"
                v-bind:key="reloadImageComponent"
              ></item-multiple-image> -->
              <item-master-image-modal
                v-if="filemarhun"
                v-bind:key="reloadImageComponent"
                v-bind:norujukan="norujukan.norujukan"
                v-bind:filemarhun_id="norujukan.filemarhun[0].id"
              ></item-master-image-modal>
              <p
                v-else
                style="
                  text-align: center;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  min-width: 300px;
                  max-width: 300px;
                "
              >
                Image Unavailable
              </p>
            </div>
            <div class="col-12 text-center lg:col-6">
              <div class="overflow-x-auto">
                <table class="table table-bordered">
                  <tbody>
                    <tr class="table-light">
                      <th>Bil</th>
                      <th style="min-width: 115px">Jenis Marhun</th>
                      <th style="min-width: 42px">Karat</th>
                      <th style="min-width: 42px">Berat</th>
                      <th style="min-width: 102px">Harga Asas</th>
                    </tr>
                    <tr v-for="(marhun, index) in marhuns" :key="marhun.id">
                      <td>{{ index + 1 }}</td>
                      <td>{{ marhun.marhun_desc }}</td>
                      <td>{{ marhun.karat }}</td>
                      <td>{{ marhun.berat }}g</td>
                      <td>
                        RM{{
                          marhun.hargaasas
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                        }}
                      </td>
                    </tr>
                    <tr style="text-transform: uppercase">
                      <td colspan="3">Jumlah</td>
                      <td>{{ norujukan.berat }}g</td>
                      <td class="disabled-bg"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row justify-content-center">
                <div class="col-6">
                  <label class="col-form-label text-end"
                    >Jumlah Harga Asas (A)</label
                  >
                </div>
                <div class="col-6">
                  <div class="input-group mb-3">
                    <span class="input-group-text">RM</span>
                    <div
                      style="background-color: #e9ecef"
                      class="form-control text-start justify-content-start"
                      disabled
                    >
                      {{
                        norujukan.hargaasas
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-6">
                  <label class="col-form-label text-end"
                    >Jumlah Harga Bida (B)</label
                  >
                </div>
                <div class="col-6">
                  <div class="input-group mb-3">
                    <span class="input-group-text">RM</span>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.hargabida"
                      @keyup="calcDiff()"
                    />
                  </div>
                </div>
              </div>
              <div class="row justify-content-center mb-3">
                <div class="col-6">
                  <label class="col-form-label text-end">Beza (B-A)</label>
                </div>
                <div class="col-6">
                  <div class="input-group">
                    <span class="input-group-text">RM</span>
                    <span class="form-control input-group-text">
                      {{
                        tambahan
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                      }}
                    </span>
                  </div>
                </div>
                <div class="row justify-content-end">
                  <div class="col-7">
                    <div
                      style="font-size: 13px"
                      class="fst-italic text-danger"
                      v-if="v$.tambahan.$error"
                    >
                      <div class="text-end">
                        *Minimum beza harga adalah RM1.
                      </div>
                      <div class="text-end">
                        *Gandaan ringgit sahaja dibenarkan.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row m-2">
            <div
              class="col"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid rgba(0, 0, 0, 0.125);
                min-height: 400px;
                min-width: 550px;
              "
            >
              -gambar marhun-
            </div>
            <div class="col">
              <table class="table table-bordered">
                <tbody>
                  <tr class="table-light">
                    <th scope="col">No</th>
                    <th scope="col">Jenis Marhun</th>
                    <th scope="col">Karat</th>
                    <th scope="col">Berat</th>
                    <th scope="col">Harga Asas</th>
                  </tr>
                  <tr v-for="(marhun, index) in marhuns" :key="marhun.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ marhun.marhun_desc }}</td>
                    <td>{{ marhun.karat }}</td>
                    <td>{{ marhun.berat }}g</td>
                    <td>
                      RM{{
                        marhun.hargaasas
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                      }}
                    </td>
                  </tr>
                  <tr style="text-transform: uppercase">
                    <td colspan="3">Jumlah</td>
                    <td>{{ norujukan.berat }}g</td>
                    <td class="disabled-bg"></td>
                  </tr>
                </tbody>
              </table>
              <div class="row justify-content-center">
                <div class="col-6">
                  <label class="col-form-label text-end"
                    >Jumlah Harga Asas (A)</label
                  >
                </div>
                <div class="col-6">
                  <div class="input-group mb-3">
                    <span class="input-group-text">RM</span>
                    <div
                      style="background-color: #e9ecef"
                      class="form-control text-start justify-content-start"
                      disabled
                    >
                      {{
                        norujukan.hargaasas
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-6">
                  <label class="col-form-label">Jumlah Harga Bida (B)</label>
                </div>
                <div class="col-6">
                  <div class="input-group mb-3">
                    <span class="input-group-text">RM</span>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.hargabida"
                      @keyup="calcDiff()"
                    />
                  </div>
                </div>
              </div>
              <div class="row justify-content-center mb-3">
                <div class="col-6">
                  <label class="col-form-label">Beza (B-A)</label>
                </div>
                <div class="col-6">
                  <div class="input-group">
                    <span class="input-group-text">RM</span>
                    <input
                      v-model="tambahan"
                      type="text"
                      id="tambahanbida"
                      class="form-control"
                      disabled
                    />
                  </div>
                </div>
                <div class="row justify-content-end">
                  <div class="col-7">
                    <div
                      style="font-size: 13px"
                      class="fst-italic text-danger"
                      v-if="v$.tambahan.$error"
                    >
                      <div class="text-end">
                        *Minimum beza harga adalah RM1.
                      </div>
                      <div class="text-end">
                        *Gandaan ringgit sahaja dibenarkan.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="modal-footer">
          <!-- <button
            type="submit"
            class="btn btn-danger me-2"
            data-bs-dismiss="modal"
          >
            Batal
          </button>
          <button type="submit" class="btn btn-success" @click="addToCart()">
            Tambah Ke Troli
          </button> -->
          <Button
            class="p-button-sm p-button-danger m-1"
            label="Kembali"
            data-bs-dismiss="modal"
          />
          <Button
            class="p-button-sm p-button-success m-1"
            label="Tambah Ke Troli"
            icon="pi pi-plus-circle"
            iconPos="right"
            :loading="isLoadingButton"
            @click="addToCart()"
          />
        </div>
      </div>
    </div>
    <!-- </form> -->
  </div>
</template>

<script>
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required, integer, numeric, minValue } from "@vuelidate/validators";
import { read } from "@popperjs/core";
import ItemMasterImage from "./ItemMasterImage.vue";
import ItemMultipleImage from "./ItemMultipleImage.vue";
import ItemMasterImageModal from "./ItemMasterImageModal.vue";

export default {
  components: {
    "item-master-image": ItemMasterImage,
    "item-multiple-image": ItemMultipleImage,
    "item-master-image-modal": ItemMasterImageModal,
  },
  emits: ["reloadComponent"],
  props: {
    selectedCawangan: { type: String },
    reloadId: { type: Number },
  },
  data() {
    return {
      layout: "grid",
      status: false,
      v$: useVuelidate(),
      isLoadingButton: false,
      loading: false,
      tambahabError: false,
      errors: [],
      lelong_details: [],
      sags: [],
      norujukan: "",
      marhuns: "",
      filemarhun: "",
      reloadImageComponent: 0,
      componentKey: 0,
      tambahan: "",
      // image: "",
      form: {
        user_id: localStorage.getItem("id"),
        lelongbid_id: "",
        hargabida: "",
      },
      myModal: null,
    };
  },
  validations() {
    return {
      tambahan: { required, numeric, integer, minValue: minValue(1) },
    };
  },
  computed: {
    numberSag() {
      if (this.sags == null) {
        return 0;
      } else {
        return this.sags.length;
      }
    },
    dataUrl() {
      // return "data:image/jpeg;base64," + this.image;
    },
  },
  mounted: function () {
    this.liveData();
  },
  methods: {
    liveData() {
      this.loading = true;
      this.status = false;
      axios
        .get("/lelongDetails/listSuratLelong", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            caw_id: this.selectedCawangan,
            user_id: localStorage.getItem("id"),
            lelong_id: localStorage.getItem("lelong_id"),
          },
        })
        .then((response) => {
          // console.log("liveData", response);
          if (response.data.success == true) {
            // let readImage = response.data.message.lelongbid[7].filemarhun[0].filename;
            // this.image = readImage;
            // console.log(this.image);
            this.status = true;
            this.lelong_details = response.data.message;
            this.response = response.data.message.lelongbid;
            this.sags = this.response;
            this.loading = false;
          } else {
            this.status = false;
            this.loading = false;
            // console.log(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.lelong_details = [null];
        });
    },
    clickModal(norujukan) {
      // this.allCarts.forEach(function (element) {
      //   if (element.lelongbid_id == norujukan.id) {
      //     // return true;
      //     console.log("ada");
      //   } else {
      //     // console.log("tiada");
      //   }
      // });
      this.norujukan = norujukan;
      this.marhuns = this.norujukan.lelongbidmarhun;
      this.reloadImageComponent += 1;
      this.filemarhun = this.norujukan.filemarhun;
      this.form.lelongbid_id = this.norujukan.id;
      this.tambahan = "";
      this.form.hargabida = "";
    },
    calcTotal() {
      this.form.hargabida =
        parseFloat(this.tambahan) + parseFloat(this.norujukan.hargaasas);
    },
    calcDiff() {
      this.tambahan =
        parseFloat(this.form.hargabida) - parseFloat(this.norujukan.hargaasas);
    },

    addToCart() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.isLoadingButton = true;
        axios
          .post("/addToCart", this.form, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.isLoadingButton = false;
            if (response.data.success == true) {
              this.$refs.tambahClose.click();
              this.$emit("reloadComponent");
              this.$swal.fire({
                toast: true,
                icon: "success",
                title: "Berjaya Tambah Ke Troli",
                animation: false,
                position: "bottom",
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
              });
            } else {
              console.log(response);
              this.$swal.fire({
                toast: true,
                icon: "error",
                title: "Gagal! Sila refresh page semula.",
                animation: false,
                position: "bottom",
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
              });
            }
            // location.reload();
          })
          .catch((error) => {
            // this.loading = false;
            this.isLoadingButton = false;
            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Gagal! Sila refresh page semula.",
              animation: false,
              position: "bottom",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          });
      } else {
        // alert("Failed");
      }
    },
  },
};
</script>


<style scoped>
.disabled-bg {
  background-color: rgb(233, 236, 239);
}

.style-photo-div {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 180px;
  min-width: 255px;
}

.container-height {
  min-height: 70vh;
}

.item-main {
    border: 1px solid #00000026;
    text-align: center;
    margin: 10px;
    border-start-start-radius: 8px;
    border-start-end-radius: 8px;
    width: 300px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.box-style {
  display: grid;
  justify-content: center;
}

.image-card {
  height: 165px;
  display: grid;
  align-content: center;
  background: #dfe3e7;
  border-start-start-radius: 7px;
  border-start-end-radius: 7px;
}

.card-arrahnbid {
  background: #ffffff;
  color: #495057;
  /* border-radius: 6px; */
}

.card-content-arrahnbid {
  padding: 1.25rem 20px;
}
</style>