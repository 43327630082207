<template>
  <Card style="margin-bottom: 1em">
    <template #title>
      <div class="flex justify-content-center flex-wrap card-container purple-container">
        <div class="font-normal">Lelongan Seterusnya</div>
        <Button
          class="button-standard p-button-rounded p-button-outlined"
          icon="pi pi-refresh"
          @click="toggleReloadComponent()"
        /></div
    ></template>
    <template #content>
      <lelong-next-detail v-bind:key="componentKey"></lelong-next-detail>
    </template>
  </Card>
</template>

<script>
import LelongNextDetail from "./LelongNextDetail.vue";

export default {
  components: {
    "lelong-next-detail": LelongNextDetail,
  },
  data() {
    return {
      loading: false,
      componentKey: 0,
    };
  },
  methods: {
    toggleReloadComponent() {
      this.componentKey += 1;
    },
  },
};
</script>

<style scoped>
.button-standard {
  height: 30px !important;
  width: 30px !important;
  margin-top: 3px !important;
}
</style>