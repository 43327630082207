// import {
//   createRouter,
//   createWebHistory,
//   createMemoryHistory,
// } from "vue-router";
// import Login from "../components/Login.vue";
// import Register from "../components/Register.vue";
// import Dashboard from "./../components/Dashboard.vue";
// import Cart from "./../components/Cart.vue";
// import List from "./../components/List.vue";
// import Pay from "../components/Pay.vue";
// import Profile from "./../components/Profile.vue";
// import BidHistory from "../components/BidHistory.vue";
// import Test from "../components/Test.vue";
// import IndexPaymentHistory from "../components/IndexPaymentHistory.vue";
// // import Modals from './../components/Modal.vue'
// // import { Modal } from "bootstrap";

// import TheLogin from '../components/authentication/TheLogin.vue'
// import TheRegister from '../components/authentication/TheRegister.vue'
// import TheHome from '../components/home/TheHome.vue'
// import TheCart from '../components/cart/TheCart.vue'
// import TheResult from '../components/result/TheResult.vue'
// import TheProfile from '../components/profile/TheProfile.vue'
// import TheBidRecord from '../components/bidRecord/TheBidRecord.vue'
// import TheList from '../components/list/TheList.vue'

// const routes = [
//   {
//     path: "/login",
//     name: "login",
//     component: TheLogin,
//   },
//   {
//     path: "/register",
//     name: "register",
//     component: TheRegister,
//   },
//   {
//     path: "/home",
//     name: "home",
//     component: TheHome,
//   },

//   {
//     path: "/cart",
//     name: "cart",
//     component: TheCart,
//   },
//   {
//     path: "/list",
//     name: "list",
//     component: TheList,
//   },
//   {
//     path: "/pay",
//     name: "Pay",
//     component: Pay,
//   },
//   {
//     path: "/profile",
//     name: "profile",
//     component: TheProfile,
//   },
//   {
//     path: "/bid-record",
//     name: "bid-record",
//     component: TheBidRecord,
//   },
//   {
//     path: "/result",
//     name: "result",
//     component: TheResult,

//   },
//   {
//     path: "/payment-history",
//     name: "payment-history",
//     component: IndexPaymentHistory,
//   },
//   {
//     path: "/test",
//     name: "test",
//     component: Test,
//   },
//   // {
//   //     name: 'Modal',
//   //     component: Modals,
//   // }
// ];

// const router = createRouter({
//   // history: createWebHistory(process.env.BASE_URL),
//   history: createWebHistory(),
//   // history: createMemoryHistory(),
//   routes,
// });

// // function loggedIn() {
// //     return true;
// // }

// // router.beforeEach((to, from, next) => {
// //     if (to.matched.some(record => record.meta.requiresAuth)) {
// //         if (!loggedIn()) {
// //             next({
// //                 // path: '/login',
// //                 // query: { redirect: to.fullPath },
// //             })
// //         }
// //         else {
// //             next()
// //         }
// //     } else if (to.matched.some(record => record.meta.guest)) {
// //         next()
// //     } else {
// //         next()
// //     }
// // })

// export default router;

import TheLogin from "../components/authentication/TheLogin.vue";
import TheRegister from '../components/authentication/TheRegister.vue'
import TheHome from "../components/home/TheHome.vue";
import TheCart from "../components/cart/TheCart.vue";
import TheResult from "../components/result/TheResult.vue";
import TheProfile from "../components/profile/TheProfile.vue";
import TheBidRecord from "../components/bid-record/TheBidRecord.vue";
import TheList from "../components/list/TheList.vue";
import ResultDetail from "../components/result/ResultDetail.vue";
import ResultReceipt from "../components/result/ResultReceipt.vue"
import ThePay from "../components/pay/ThePay.vue";
import ThePayCdm from "../components/pay/ThePayCdm.vue";
import ThePolicy from "../components/policy/ThePolicy.vue";
import TheForgotPassword from "../components/authentication/TheForgotPassword.vue";

import {
  createRouter,
  createWebHistory,
  createMemoryHistory,
} from "vue-router";
// import Register from "../components/Register.vue";
// import IndexPaymentHistory from '../components/result/IndexPaymentHistory.vue'
// import Modals from './../components/Modal.vue'
// import { Modal } from "bootstrap";

const routes = [
  {
    path: "/login",
    name: "login",
    component: TheLogin,
    // meta: { guest: true }
  },
  {
    path: "/register",
    name: "register",
    component: TheRegister,
    // meta: { guest: true }
  },
  {
    path: "/",
    name: "login",
    component: TheLogin,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: TheForgotPassword,
  },
  {
    path: "/home",
    name: "home",
    component: TheHome,
  },
  {
    path: "/cart",
    name: "cart",
    component: TheCart,
    // meta: { requiresAuth: true }
  },
  {
    path: "/list",
    name: "list",
    component: TheList,
    // meta: { requiresAuth: true }
  },
  {
    path: "/profile",
    name: "profile",
    component: TheProfile,
    // meta: { requiresAuth: true }
  },
  {
    path: "/bid-record",
    name: "bid-record",
    component: TheBidRecord,
    // meta: { requiresAuth: true }
  },
  {
    path: "/result",
    name: "result",
    component: TheResult,
    // meta: { requiresAuth: true }
  },
  {
    path: "/result-detail",
    name: "detail",
    component: ResultDetail,
    // meta: { requiresAuth: true }
  },
  {
    path: "/result/detail/receipt",
    name: "receipt",
    component: ResultReceipt,
    // meta: { requiresAuth: true }
  },
  {
    path: "/pay",
    name: "pay",
    component: ThePay,
  },
  {
    path: "/pay-cdm",
    name: "pay-cdm",
    component: ThePayCdm,
  },
  {
    path: "/policy",
    name: "policy",
    component: ThePolicy,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHistory(),
  // history: createMemoryHistory(process.env.BASE_URL),
  routes,
});

export default router;
