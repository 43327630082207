import { defineStore } from 'pinia'

export const useAuthenticationUserStore = defineStore('authenticationUser', {
    state: () => ({
        token: "",
        id: "",
        nokp: "",
        name: "",
        email: "",
        notelbimbit: "",
        username: ""
    }),
    getters: {
        getToken() {
            return this.token;
        },
        getId() {
            return this.id;
        },
        getNokp() {
            return this.nokp;
        },
        getName() {
            return this.name;
        },
        getEmail() {
            return this.email;
        },
        getNoTelBimbit() {
            return this.notelbimbit;
        },
        getUsername() {
            return this.username;
        },
    },
    actions: {
    },
    persist: true,
})