<template>
  <the-template>
    <div v-if="isLoading" class="fadein animation-duration-400">
      <div
        class="flex align-items-center justify-content-center container-height"
      >
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div v-else class="fadein animation-duration-400">
      <div v-if="isSuccess">
        <Card>
          <template #title>
            <div class="flex">
              <Button
                class="
                  p-button-sm
                  button-custom
                  p-button-secondary p-button-outlined
                  align-self-center
                  mr-2
                "
                icon="pi pi-arrow-left"
                v-on:click="clickCancelButton()"
              />
              <div class="font-normal">Pembayaran Bidaan Menggunakan CDM</div>
            </div>
            <div class="font-normal text-base">
              Tarikh Lelong : {{ tarikhLelong }}
            </div>
          </template>
          <template #content>
            <!-- <select-outlet
              v-bind:namaCawangan="lists"
              v-bind:lelongIdProps="lelongId"
            ></select-outlet> -->
            <div class="font-medium p-0">No Account Bank: 1101515411 (Bank Rakyat)</div>
            <span class="text-xs fst-italic text-danger">
              *Sila buat bayaran menggunakan nombor akaun ini sahaja dan muat
              naik gambar resit dibawah.</span
            >

            <div class="overflow-x-auto">
              <table class="mt-5 table text-center table-bordered">
                <tbody>
                  <tr class="table-light">
                    <td>Bil</td>
                    <td style="min-width: 240px">Cawangan</td>
                    <td style="min-width: 120px">Jumlah Harga Bida</td>
                  </tr>
                  <tr>
                    <td>*</td>
                    <td>KOTA BHARU 2</td>
                    <td>RMXXXX</td>
                  </tr>
                  <tr>
                    <td>*</td>
                    <td>WAKAF BHARU</td>
                    <td>RMXXXX</td>
                  </tr>
                  <tr class="uppercase text-danger">
                    <th colspan="2">Amaun yang perlu di bank-in</th>
                    <th>RMXXXXX</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <Button
              class="p-button-sm mb-3"
              label="Muat Naik Resit"
              icon="pi pi-plus-circle"
              iconPos="right"
              @click="openModalFileUpload()"
            />
            <list-receipt
              v-bind:key="componentKey"
              v-bind:lelong_id="lelongId"
              v-on:reloadComponent="toggleReloadComponent"
            ></list-receipt>

            <Dialog
              header="Muat Naik Resit Baharu"
              v-model:visible="isDisplayFileUpload"
              :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
              :style="{ width: '50vw' }"
              :modal="true"
            >
              <div class="text-center m-2">
                <input class="form-control" type="file" accept="image/*" v-on:change="selectPhoto" />
                <!-- <FileUpload
                  name="demo[]"
                  url="./upload"
                  accept="image/*"
                  :maxFileSize="1500000"
                  :fileLimit="1"
                  invalidFileSizeMessage="{0}: Melebihi saiz yang ditetapkan, saiz fail hendaklah tikal melebihi 1.5 MB."
                /> -->
              </div>
              <template #footer>
                <Button
                  class="p-button-sm p-button-secondary"
                  label="Kembali"
                  @click="closeModalFileUpload()"
                />
                <Button
                  class="p-button-sm p-button-success"
                  label="Simpan"
                  icon="pi pi-check"
                  iconPos="right"
                  @click="addPhotoResit()"
                />
              </template>
            </Dialog>
          </template>
        </Card>
      </div>
      <div v-else>
        <div
          class="
            flex
            align-items-center
            justify-content-center
            container-height
          "
        >
          <div class="grid justify-content-center">
            <div class="col-12 mb-4 text-center">
              <i class="pi pi-book text-8xl text-400"></i>
            </div>
            <div class="col-12 text-center">
              <span class="text-400">Tiada Data.</span>
            </div>
            <div class="col-12 text-center">
              <span class="text-400"
                >Sila rujuk halaman
                <router-link v-bind:to="{ name: 'result' }"
                  >Keputusan</router-link
                >
                untuk maklumat rekod bidaan menang.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </the-template>
</template>

<script>
import axios from "axios";
import ListReceipt from "./ListReceipt.vue";

export default {
  components: {
    "list-receipt": ListReceipt,
  },
  data() {
    return {
      lists: [],
      tarikhLelong: "",
      lelongId: "",
      isLoading: false,
      isSuccess: false,
      isDisplayFileUpload: false,
      componentKey: 0,
      formResit: {
        user_id: localStorage.getItem("id"),
        lelong_id: this.$route.params.lelongId,
        gambarResit: "",
      },
    };
  },
  created() {
    this.lelongId = this.$route.params.lelongId;
  },
  mounted: function () {
    this.liveData();
    // this.getInvoice();
  },
  methods: {
    liveData() {
      this.isLoading = true;
      axios
        .get(
          "/bayarBaki/" +
            localStorage.getItem("id") +
            // "/1" ,
            "/" +
            this.lelongId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // console.log("liveData", response);
          this.isLoading = false;
          if (response.data.success == true) {
            this.isSuccess = true;
            this.lists = response.data.data;
            this.tarikhLelong = response.data.tarikh_lelong;
          } else {
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    selectPhoto(e) {
      console.log(e);
      this.formResit.gambarResit = e.target.files[0];
    },
    addPhotoResit() {
      var resitFormData = new FormData();
      resitFormData.append("gambarResit", this.formResit.gambarResit);
      resitFormData.append("lelong_id", this.formResit.lelong_id);
      resitFormData.append("user_id", this.formResit.user_id);
      axios
        .post("/addPhotoResit", resitFormData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.isDisplayFileUpload = false;
          if (response.data.success == true) {
            this.$swal.fire({
              icon: "success",
              title: "Gambar Resit Berjaya Di Muat Naik",
              confirmButtonColor: "#344871",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
            this.componentKey += 1;
          } else if (response.data.success == false) {
            console.log(response);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Gagal! Sila refresh page semula.",
              animation: false,
              position: "bottom",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
            this.componentKey += 1;
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    openModalFileUpload() {
      this.isDisplayFileUpload = true;
    },
    closeModalFileUpload() {
      this.isDisplayFileUpload = false;
    },
    clickCancelButton() {
      this.$router.push({
        name: "detail",
        params: {
          lelongId: this.lelongId,
          statusPayment: null,
        },
      });
    },
    toggleReloadComponent() {
      this.componentKey += 1;
    },
  },
};
</script>

<style scoped>
.container-height {
  min-height: 70vh;
}
</style>