import { createApp } from 'vue'
import App from './App.vue'
import Master from './Master.vue'
import router from './router'
// import useVuelidate from '@vuelidate/core'
import './interceptors/axios'
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.js"
import 'bootstrap-icons/font/bootstrap-icons.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import PrimeVue from 'primevue/config';

import '../public/themes/lara-light-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import ToastService from 'primevue/toastservice';

import Card from 'primevue/card';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import ProgressBar from 'primevue/progressbar';
import Toast from 'primevue/toast';
import Sidebar from 'primevue/sidebar';
import Message from 'primevue/message';
import InlineMessage from 'primevue/inlinemessage';
import Skeleton from 'primevue/skeleton';
import Password from 'primevue/password';
import InputText from 'primevue/inputtext';
import FileUpload from 'primevue/fileupload';
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Dialog from 'primevue/dialog';
import Toolbar from 'primevue/toolbar';
import Avatar from 'primevue/avatar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tag from 'primevue/tag';
import Image from 'primevue/image';
import Galleria from 'primevue/galleria';
import BadgeDirective from 'primevue/badgedirective';
import Textarea from 'primevue/textarea';
import Tooltip from 'primevue/tooltip';
import ScrollTop from 'primevue/scrolltop';

import 'maz-ui/css/main.css'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

import YouTube from "vue3-youtube";

import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

import TheTemplate from './components/layouts/TheTemplate.vue'
import BaseCard from './components/base/BaseCard.vue'
import TermsAndConditions from './components/authentication/TermsAndConditions.vue'

const app = createApp(App);
// const app = createApp(Master);

app.component('the-template', TheTemplate);
app.component('base-card', BaseCard);
app.component('terms-and-conditions', TermsAndConditions);

app.component('Card', Card);
app.component('Button', Button);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('ProgressBar', ProgressBar);
app.component('Toast', Toast);
app.component('Sidebar', Sidebar);
app.component('Message', Message);
app.component('InlineMessage', InlineMessage);
app.component('Skeleton', Skeleton);
app.component('Password', Password);
app.component('InputText', InputText);
app.component('FileUpload', FileUpload);
app.component('Checkbox', Checkbox);
app.component('Checkbox', Checkbox);
app.component('RadioButton', RadioButton);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Dialog', Dialog);
app.component('Avatar', Avatar);
app.component('Toolbar', Toolbar);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Image', Image);
app.component('Galleria', Galleria);
app.directive('badge', BadgeDirective);
app.component('Textarea', Textarea);
app.directive('tooltip', Tooltip);
app.component('ScrollTop', ScrollTop);

app.component('MazPhoneNumberInput', MazPhoneNumberInput)

app.component('YouTube', YouTube)

app.use(router).use(pinia).use(PrimeVue).use(VueSweetalert2).use(ToastService).use(VueViewer).mount('#app');
// app.use(router).use(store).use(PrimeVue).use(ToastService).mount('#app');

// createApp(App).use(router).use(store).mount('#app')
// createApp(Master).use(router).use(store).mount('#app')
// createApp(App).use(router).use(store).use(useVuelidate).mount('#app')
