<template>
  <the-template>
    <bid-record-list v-bind:key="statusComponent"></bid-record-list>
  </the-template>
</template>

<script>
import BidRecordList from "./BidRecordList.vue";

export default {
  components: {
    "bid-record-list": BidRecordList,
  },
  data() {
    return {
      statusComponent: 0,
      date: "",
    };
  },
  mounted: function () {
    this.getDate();
  },
  methods: {
    reloadComponent() {
      this.statusComponent += 1;
    },
    getDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
      this.date = today;
    },
  },
};
</script>

<style scoped>
h2 {
  font-weight: 600;
}
</style>