<template>
  <the-template>
    <div class="col-12 lg:col-11 fadein animation-duration-400">
      <Card>
        <template #title>
          <div class="font-normal">Polisi</div>
        </template>
        <template #content>
          <TabView>
            <TabPanel :header="'Panduan E-Rahn Lelong'">
              <p>
                Berikut merupakan video panduan untuk penggunaan E-Rahn Lelong. Sila
                tonton
                <a
                  class="font-bold"
                  target="_blank"
                  href="https://youtu.be/lucYE9bM_PY"
                  >video</a
                >
                ini untuk memahami cara penggunaan.
              </p>
              <div class="lg:flex hidden justify-content-center">
                <YouTube v-bind:src="theUrl" />
              </div>
              <div
                class="
                  lg:hidden
                  md:flex
                  hidden
                  overflow-x-auto
                  justify-content-center
                "
              >
                <YouTube v-bind:src="theUrl" width="480" height="360" />
              </div>
              <div
                class="
                  lg:hidden
                  md:hidden
                  flex
                  overflow-x-auto
                  justify-content-center
                "
              >
                <YouTube v-bind:src="theUrl" width="320" height="240" />
              </div>
            </TabPanel>
            <TabPanel :header="'Terma & Syarat'">
              <terms-and-conditions-details></terms-and-conditions-details>
            </TabPanel>
            <TabPanel :header="'Hubungi Kami'">
              <div class="mt-5">
                <div
                  class="
                    grid
                    col-11 col-offset-1
                    lg:col-8 lg:col-offset-2
                    justify-content-center
                  "
                >
                  <div class="col-12 lg:col-5 text-start text-400"><i class="pi pi-phone"></i> Hotline</div>
                  <div class="col-12 lg:col-6 text-start value-form">096205608</div>
                  <div class="col-12 lg:col-5 text-start text-400"><i class="pi pi-pencil"></i> Emel</div>
                  <div class="col-12 lg:col-6 text-start value-form">arrahnlosong@gmail.com</div>

                  <!-- <div class="col-12 lg:col-5 text-start text-400"><i class="pi pi-paperclip"></i> Emel</div>
                  <div class="col-12 lg:col-6 text-start value-form">admin@koppkb.com</div> -->
                </div>
              </div>
            </TabPanel>
          </TabView>
        </template>
      </Card>
    </div>
    <ScrollTop :threshold="200" />
  </the-template>
</template>

<script>
import TermsAndConditionsDetails from "../authentication/TermsAndConditionsDetails.vue";

export default {
  components: {
    "terms-and-conditions-details": TermsAndConditionsDetails,
  },
  data() {
    return {
      theUrl: "https://www.youtube.com/watch?v=lucYE9bM_PY&t=53s",
    };
  },
};
</script>

<style scoped>
.value-form {
  border: 1px solid #ced4da;
  margin-bottom: 0.5rem;
  border-radius: 6px;
}
</style>

<!-- export default {
  components: {
    "terms-and-conditions-details": TermsAndConditionsDetails,
  },
};
</script> -->