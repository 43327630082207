<template>
  <the-template>
    <result-main v-bind:key="statusComponent"></result-main>
  </the-template>
</template>

<script>
import ResultMain from "./ResultMain.vue";
export default {
  components: {
    "result-main": ResultMain,
  },
  data() {
    return {
      statusComponent: 0,
    };
  },
  methods: {
    reloadComponent() {
      this.statusComponent += 1;
    },
  },
};
</script>