<template>
  <Toast :breakpoints="{ '767px': { width: '100%', right: '0', left: '0' } }" />
  <div class="main-container">
    <div
      class="flex min-h-screen align-items-center justify-content-center p-5"
    >
      <div
        class="
          surface-card
          fadein
          p-4
          shadow-2
          border-round
          w-11
          md:w-10
          lg:w-10
          xl:w-8
        "
      >
        <div class="text-center mb-3">
          <div class="text-900 text-3xl font-semibold mb-3">            
            <img class="logo-img" src="img/e-rahn-lelong.png" alt="arrahn-logo" />
            <div class="text-200 text-3xl font-semibold mb-3 mt-3">Daftar Akaun</div>
          </div>
          <!-- <span class="text-600 font-medium line-height-3"
            >Sudah punyai akaun?</span
          >
          <router-link
            v-bind:to="{ name: 'login' }"
            class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
            >Log masuk sekarang!</router-link
          > -->
        </div>
        <!-- <h5>Daftar Akaun</h5> -->
        <div class="grid">
          <div class="col-12 lg:col-6">
            <div class="field text-200">
              <label for="name">Nama</label>
              <InputText
                id="name"
                type="text"
                v-model="form.name"
                class="
                  text-base text-color
                  surface-overlay
                  p-2
                  border-1 border-solid
                  surface-border
                  border-round
                  appearance-none
                  outline-none
                  focus:border-primary
                  w-full
                "
              ></InputText>
              <InlineMessage class="mt-1" v-if="v$.form.name.$error"
                >Nama diperlukan</InlineMessage
              >
            </div>
          </div>
          <div class="col-12 lg:col-6">
            <div class="field text-200">
              <label for="nokp">No Kad Pengenalan</label>
              <!-- <select
                class="p-0 mb-2 form-select"
                style="width: 190px; border: none"
              >
                <option selected value="nokp">No Kad Pengenalan</option>
                <option value="passpor">No Passport</option>
              </select> -->
              <InputText
                id="nokp"
                type="text"
                v-model="form.nokp"
                class="
                  text-base text-color
                  surface-overlay
                  p-2
                  border-1 border-solid
                  surface-border
                  border-round
                  appearance-none
                  outline-none
                  focus:border-primary
                  w-full
                "
              ></InputText>
              <InlineMessage class="mt-1" v-if="v$.form.nokp.$error"
                >Semak No KP</InlineMessage
              >
            </div>
          </div>
          <div class="col-12">
            <div class="field text-200">
              <label for="alamat">Alamat</label>
              <Textarea
                id="alamat"
                v-model="form.alamat"
                class="
                  text-base text-color
                  surface-overlay
                  p-2
                  border-1 border-solid
                  surface-border
                  border-round
                  appearance-none
                  outline-none
                  focus:border-primary
                  w-full
                "
              ></Textarea>
              <InlineMessage class="mt-1" v-if="v$.form.alamat.$error"
                >Alamat diperlukan</InlineMessage
              >
            </div>
          </div>
          <div class="col-12 lg:col-6">
            <div class="field text-200">
              <label for="notelbimbit">No Telefon</label>
              <MazPhoneNumberInput
                v-model="form.notelbimbit"
                show-code-on-list
                :fetch-country="true"
                :preferred-countries="['MY']"
                :translations="{
                  countrySelector: {
                    placeholder: 'Pilih Negara',
                    error: 'Choose country',
                  },
                  phoneInput: {
                    placeholder: 'Nombor Telefon',
                  },
                }"
                :no-example="true"
                @update="results = $event"
              />
              <InlineMessage class="mt-1" v-if="v$.form.notelbimbit.$error"
                >No telefon diperlukan</InlineMessage
              >
            </div>
          </div>
          <div class="col-12 lg:col-6">
            <div class="field text-200">
              <label for="email">Emel</label>
              <InputText
                id="email"
                type="text"
                v-model="form.email"
                class="
                  text-base text-color
                  surface-overlay
                  p-2
                  border-1 border-solid
                  surface-border
                  border-round
                  appearance-none
                  outline-none
                  focus:border-primary
                  w-full
                  h-match-phone-number
                "
              ></InputText>
              <InlineMessage class="mt-1" v-if="v$.form.email.$error"
                >Sila isi emel yang sah</InlineMessage
              >
            </div>
          </div>
          <div class="col-12 lg:col-6">
            <div class="field text-200">
              <p class="mb-0" for="username">Username</p>
              <InputText
                id="username"
                type="text"
                v-model="form.username"
                class="
                  text-base text-color
                  surface-overlay
                  p-2
                  border-1 border-solid
                  surface-border
                  border-round
                  appearance-none
                  outline-none
                  focus:border-primary
                  w-full
                  h-match-password
                "
              ></InputText>
              <InlineMessage class="mt-1" v-if="v$.form.username.$error"
                >Username diperlukan</InlineMessage
              >
            </div>
          </div>
          <div class="col-12 lg:col-6">
            <div class="field text-200">
              <p class="mb-0">Kata Laluan</p>
              <div class="p-input-icon-right">
                <i class="pi pi-user" />
                <Password
                  v-model="form.password"
                  toggleMask
                  :feedback="false"
                  class="
                    text-base text-color
                    surface-overlay surface-border
                    border-round
                    appearance-none
                    outline-none
                    focus:border-primary
                    w-full
                  "
                />
              </div>
              <InlineMessage class="mt-1" v-if="v$.form.password.$error"
                >Kata laluan diperlukan & mestilah 8 huruf</InlineMessage
              >
            </div>
          </div>
          <!-- <div class="col-12">
            <div class="field">
              <label for="frontic">Kad Pengenalan Hadapan</label>
              <input
                class="form-control"
                type="file"
                id="formFile"
                ref="formFileRef"
                @change="readImage()"
              />
              <InlineMessage class="mt-1" v-if="v$.form.images.file1.$error"
              >Kad pengenalan hadapan diperlukan</InlineMessage
            >
            </div>
          </div>
          <div class="col-12">
            <div class="field">
              <label for="backic">Kad Pengenalan Belakang</label>
              <input class="form-control" type="file" />
              <InlineMessage class="mt-1" v-if="v$.form.images.file2.$error"
              >Kad pengenalan belakang diperlukan</InlineMessage
            >
            </div>
          </div> -->
          <div class="col-12">
            <div class="mt-3 mb-1">
              <label>
                <input type="checkbox" v-model="form.register_agree" />
                Setuju Dengan <terms-and-conditions></terms-and-conditions>
              </label>
            </div>
            <InlineMessage v-if="v$.form.register_agree.$error"
              >Anda mestilah bersetuju dengan terma & syarat.</InlineMessage
            >
          </div>
          <div class="col-12 text-center">
            <Button
              class="p-button-sm p-button-outlined m-1"
              icon="pi pi-times"
              iconPos="right"
              label="Batal Pendaftaran"
              @click="toLogin()"
            />
            <Button
              class="p-button-sm m-1"
              label="Teruskan"
              icon="pi pi-sign-in"
              iconPos="right"
              @click="openModalTNC()"
            />
          </div>
          <div class="col-12 text-center">
            <span class="text-200 font-medium line-height-3"
            >Sudah punyai akaun?
            </span>
            <router-link
            v-bind:to="{ name: 'login' }"
            class="font-medium no-underline ml-2 text-teal-600 cursor-pointer"
            >Log masuk sekarang!</router-link>
          </div>      
        </div>
      </div>
      <Dialog
        header="Terma & Syarat"
        v-model:visible="isDisplayTNC"
        :style="{ width: '80vw' }"
        :modal="true"
      >
        <div>
          <terms-and-conditions-details></terms-and-conditions-details>
        </div>
        <template #footer>
          <Button
            label="Batal Pendaftaran"
            icon="pi pi-times"
            iconPos="right"
            @click="closeModalTNC()"
            class="p-button-text"
          />
          <Button
            class="p-button-sm"
            label="Daftar Sekarang"
            :loading="isLoading"
            icon="pi pi-sign-in"
            iconPos="right"
            @click="doRegister()"
          ></Button>
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, maxLength } from "@vuelidate/validators";
import TermsAndConditionsDetails from "./TermsAndConditionsDetails.vue";
import { useAuthenticationUserStore } from "@/store/modules/authentication"

export default {
  name: "Register",
  components: {
    "terms-and-conditions-details": TermsAndConditionsDetails,
  },
  data() {
    return {
      v$: useVuelidate(),
      alertMessages: Array,
      isLoading: false,
      isDisplayTNC: false,
      form: {
        name: "",
        username: "",
        nokp: "",
        password: "",
        email: "",
        notelbimbit: "",
        register_agree: null,
        alamat: "",
        // password_confirmation: "",
        images: {
          file1: {
            file_type: "png",
            filename: "imagein64string",
            // file_type: null,
            // filename: null,
          },
          file2: {
            file_type: "jpg",
            filename: "imagein64string",
            // file_type: null,
            // filename: null,
          },
        },
      },
    };
  },
  setup() {
    const authenticationStore = useAuthenticationUserStore()
    return { authenticationStore }
  },
  methods: {
    doRegister() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.isLoading = true;
        axios
          .post("/register", this.form)
          .then((response) => {
            // console.log("doRegister", response);
            if (response.data.success == true) {
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("id", response.data.user.id);
              localStorage.setItem("nokp", response.data.user.nokp);
              localStorage.setItem("name", response.data.user.name);
              localStorage.setItem("email", response.data.user.email);
              localStorage.setItem(
                "notelbimbit",
                response.data.user.notelbimbit
              );
              localStorage.setItem("username", response.data.user.username);
              this.authenticationStore.$patch({
                token: response.data.token,
                id: response.data.user.id,
                nokp: response.data.user.nokp,
                name: response.data.user.name,
                email: response.data.user.email,
                notelbimbit: response.data.user.notelbimbit,
                username: response.data.user.username,
              })
              this.$router.push({ name: "home" });
              this.isLoading = false;
            } else if (response.data.success == false) {
              this.isLoading = false;
              this.isDisplayTNC = false;
              this.alertMessages = response.data.message;
              Object.values(this.alertMessages).forEach((val) =>
                this.$toast.add({
                  severity: "warn",
                  summary: "Failed to register!",
                  detail: val[0],
                })
              );
            } else if (response.data.success == "Staff") {
              this.isLoading = false;
              this.isDisplayTNC = false;
              this.$toast.add({
                severity: "warn",
                summary: "Failed to register!",
                detail: response.data.message,
              });
            } else {
              this.isLoading = false;
              alert(response);
              console.log(response);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            alert(error);
            console.log(error);
          });
      }
    },
    openModalTNC() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.isDisplayTNC = true;
      }
    },
    closeModalTNC() {
      this.isDisplayTNC = false;
    },
    toLogin() {
      this.$router.push({ name: "login" });
    },
  },
  validations() {
    return {
      form: {
        name: { required },
        username: { required },
        nokp: {
          required,
          minLengthValue: minLength(12),
          maxLengthValue: maxLength(12),
        },
        password: { required, minLengthValue: minLength(8) },
        email: { required, email },
        notelbimbit: { required, minLengthValue: minLength(8) },
        register_agree: { required },
        alamat: { required },
        // images: {
        //   file1: {
        //     filename: { required },
        //   },
        //   file2: {
        //     filename: { required },
        //   },
        // },
      },
    };
  },
};
</script>

<style scoped>
.main-container {
  background: url("/src/assets/background-lelong.png");
  background-position: center;
  background-size: cover;
}

.logo-img {
  width: 300px;
  height: 55px;
}

.h-match-phone-number {
  height: 48px;
}

.h-match-password {
  height: 42px;
}
</style>
