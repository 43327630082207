<template>
    <Toast :breakpoints="{ '767px': { width: '100%', right: '0', left: '0' } }" />
    <div class="flex main-container min-h-screen align-items-center justify-content-center">
        <div class="surface-card fadein p-4 shadow-2 border-round w-11 md:w-10 lg:w-8 xl:w-7 content-bg">
            <div class="text-center mb-5">
                <div class="text-900 text-3xl font-semibold mb-3">
                    Lelong Erahn
                    <!-- <img class="logo-img" src="img/logo_arrahn.png" alt="arrahn-logo" /> -->
                </div>
                <span class="text-600 font-medium line-height-3">Belum punyai akaun?</span>
                <router-link v-bind:to="{ name: 'register' }"
                    class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Daftar sekarang!</router-link>
            </div>
            <form @submit.prevent="submit">
                <div class="px-0 text-center md:px-8">
                    <div class="field grid">
                        <label for="firstname3" class="justify-content-end" style="width: 170px">Emel</label>
                        <div class="col">
                            <span class="p-input-icon-right">
                                <i class="pi pi-user"></i>
                                <InputText class="mb-2" type="text" v-model="email" required></InputText>
                            </span>
                        </div>
                    </div>
                    <div class="">
                        <Button @click="doForgotPassword()" type="submit" class="p-button-sm" label="Lupa Katalaluan"
                            icon="pi pi-sign-in" :loading="isLoading" iconPos="right"></Button>
                    </div>
                </div>
            </form>
            <div class="mt-3 text-center">
                <router-link v-bind:to="{ name: 'login' }">Kembali ke Log Masuk</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Login",
    data() {
        return {
            isLoading: false,
            email: "",
        };
    },
    methods: {
        doForgotPassword() {
            this.isLoading = true;
            axios
                .post("/forgotPassword", { email: this.email },)
                .then((response) => {
                    console.log(response);
                    if (response.data.success == true) {
                        this.$swal.fire({
                            icon: "success",
                            title: "Pautan Lupa Katalaluan Telah Dihantar!",
                            text: "Sila semak emel anda untuk tindakan lebih lanjut",
                            confirmButtonColor: "#344871",
                            confirmButtonText: "OK",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                        });
                        this.email = "";
                        this.isLoading = false;
                    } else if (response.data.success == false) {
                        if (response.data.status == 0) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Emel tidak dijumpai",
                                detail: "Sila semak emel anda",
                                life: 10000,
                            });
                            this.isLoading = false;
                        } else if (response.data.status == 500) {
                            this.$swal.fire({
                                icon: "error",
                                title: "Failed!",
                                text: "There is some issue with email provider, please contact admin.",
                                confirmButtonColor: "#344871",
                                confirmButtonText: "OK",
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                            });
                        }
                    }
                })
                .catch((response) => {
                    alert(response);
                    this.isLoading = false;
                });
        }
    }
}

</script>

<style scoped>
.main-container {
    background: url("/src/assets/background-lelong.png");
    background-position: center;
    background-size: cover;
}

.content-bg {
    background: #fff9f9eb !important;
}

.logo-img {
    width: 84px;
    height: 44px;
}
</style>