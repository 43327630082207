<template>
  <div class="content-page" style="background-color: #eeeeee;">
    <div class="content" style="background-color: #eeeeee;">
      <the-header />
      <br>
      <div class="container pt-2" style="background-color: #FAE79A;">
        <slot></slot>
      </div>
    </div>
    <footer class="footer">
      <the-footer />
    </footer>
  </div>
</template>

<script>
import TheHeader from "./TheHeader.vue";
import TheFooter from "./TheFooter.vue";

export default {
  components: {
    "the-header": TheHeader,
    "the-footer": TheFooter,
  },
};
</script>

<style scoped>
.content-page {
  position: relative;
  /* overflow: hidden; */
  padding: 0 0 100px;
  min-height: 100vh;
  background: #eff3f8;
}

.footer {
  bottom: 0;
  position: absolute;
  color: #98a6ad;
  width: 100%;
}
</style>


