<template>
  <the-template>
    <div
      v-if="loading"
      style="min-height: 70vh; display: grid; place-items: center"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else class="fadein animation-duration-400">
      <div v-if="isSuccess">
        <div class="row">
          <Card>
            <template #title>
              <div class="grid justify-content-between">
                <div class="flex">
                  <Button
                    class="
                      p-button-sm
                      button-custom
                      p-button-secondary p-button-outlined
                      align-self-center
                      mr-2
                    "
                    icon="pi pi-arrow-left"
                    v-on:click="clickBackButton()"
                  />
                  <div
                    class="font-normal"
                    style="font-size: 1.5rem; font-weight: 700"
                  >
                    Keputusan Tarikh Lelongan: {{ tarikhLelong }}
                  </div>
                </div>
                <div class="">
                  <!-- <Button
                    label="Bayar CDM"
                    icon="pi pi-money-bill"
                    iconPos="right"
                    class="p-button-sm p-button-help mr-2"
                    :disabled="disabled == 1"
                    v-on:click="clickPayCdmButton(lelongId)"
                  /> -->
                  <Button
                    label="Pembayaran"
                    icon="pi pi-dollar"
                    iconPos="right"
                    class="p-button-sm"
                    :disabled="disabled == 1"
                    v-on:click="clickPayButton(lelongId)"
                  />
                </div>
              </div>
              <div class="row" v-if="disabled != 1">
                <div
                  class="
                    text-xs
                    align-self-center
                    fst-italic
                    fw-lighter
                    text-danger
                    md:text-right
                  "
                >
                  *Sila klik butang diatas untuk lakukan bayaran.
                </div>
              </div>
            </template>
            <template #content>
              <Accordion :multiple="true">
                <AccordionTab v-for="list in lists" v-bind:key="list">
                  <template #header>
                    <div class="flex justify-content-between w-100">
                      <div class="align-self-center" style="margin: 5px">
                        {{ list.cawangan }}
                        <span
                          v-if="list.status_payment_cawangan.status === null"
                        >
                          <Tag
                            value="Belum Bayar"
                            icon="pi pi-times"
                            severity="danger"
                          ></Tag>
                        </span>
                        <span
                          v-else-if="list.status_payment_cawangan.status == 9"
                        >
                          <Tag
                            value="Tunggu Pengesahan"
                            icon="pi pi-stopwatch"
                            severity="warning"
                          ></Tag>
                        </span>
                        <span
                          v-else-if="list.status_payment_cawangan.status == 1"
                        >
                          <Tag
                            value="Selesai Bayar"
                            icon="pi pi-check"
                            severity="success"
                          ></Tag>
                        </span>
                      </div>
                      <div class="style-info">
                        Jumlah RM{{
                          list.total_hargabida
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                        }}
                      </div>
                    </div>
                  </template>
                  <Button
                    label="Surat Hak Kepemilikan Barang"
                    icon="pi pi-verified"
                    iconPos="right"
                    class="p-button-sm mb-3"
                    v-on:click="downloadPdf(list.kodcaw)"
                    v-if="list.status_payment_cawangan.status == 1"
                  />
                  <div
                    v-if="
                      list.status_payment_cawangan.status == 1 ||
                      list.status_payment_cawangan.status == 9
                    "
                  >
                    <div
                      class="m-2"
                      v-if="
                        list.status_payment_cawangan.payment_method_opt == 2
                      "
                    >
                      <div>
                        <div class="row">
                          <div>Cara Bayaran: FPX</div>
                        </div>
                        <div class="row">
                          <div>
                            Masa Bayaran:
                            {{ list.status_payment_cawangan.resit.pay_time }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="m-2"
                      v-else-if="
                        list.status_payment_cawangan.payment_method_opt == 1
                      "
                    >
                      <div>
                        <div class="row">
                          <div>Cara Bayaran: TUNAI DI CAWANGAN</div>
                        </div>
                        <div class="row">
                          <div>
                            Masa Bayaran:
                            {{ list.status_payment_cawangan.resit.pay_time }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="mt-2 mb-2"
                      v-else-if="
                        list.status_payment_cawangan.payment_method_opt == 3
                      "
                    >
                      <div>
                        <div class="row mb-3">
                          <div>Cara Bayaran: CDM</div>
                        </div>
                        <div
                          v-if="
                            list.status_payment_cawangan.status == 9 &&
                            list.status_payment_cawangan.file_resit.length < 15
                          "
                        >
                          <Button
                            class="p-button-sm mb-1"
                            label="Tambah Resit Baharu"
                            icon="pi pi-plus-circle"
                            iconPos="right"
                            @click="openModalFileUpload(list.kodcaw)"
                          />
                          <Dialog
                            header="Muat Naik Resit Baharu"
                            v-model:visible="isDisplayFileUpload"
                            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
                            :style="{ width: '50vw' }"
                            :modal="true"
                          >
                            <div class="text-center">
                              <div>Lakukan bayaran ke akaun tersebut.</div>
                              <div class="div-card-bank">
                                <div class="card-bank">
                                  <div>
                                    <div>Koperasi Rakyat Kelantan Berhad</div>
                                    <div>1101515411</div>
                                    <div>Bank Rakyat</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="mt-5">Sila muat naik gambar resit dalam bentuk <strong>PNG</strong> atau <strong>JPG</strong>.</div>
                            <div>Pastikan gambar yang dimuat naik adalah <strong>JELAS.</strong></div>
                            <div class="text-center m-2">
                              <input
                                class="form-control"
                                type="file"
                                accept="image/png, image/jpg, image/jpeg"
                                v-on:change="selectPhoto"
                              />
                              <div
                                style="font-size: 13px"
                                class="fst-italic text-danger row"
                                v-if="v$.fileSize.$error"
                              >
                                <div>
                                  Jenis gambar yang diterima adalah PNG dan JPG.
                                </div>
                              </div>
                            </div>
                            <template #footer>
                              <Button
                                class="p-button-sm p-button-secondary"
                                label="Kembali"
                                @click="closeModalFileUpload()"
                              />
                              <Button
                                class="p-button-sm p-button-success"
                                label="Simpan"
                                icon="pi pi-check"
                                iconPos="right"
                                :loading="isLoadingButton"
                                @click="addPhotoResit()"
                              />
                            </template>
                          </Dialog>
                        </div>
                        <div class="card">
                          <div class="card-header">Gambar Resit</div>
                          <div
                            class="
                              card-body
                              d-flex
                              overflow-x-auto
                              align-items-center
                            "
                          >
                            <div
                              class="m-2"
                              v-for="receipt in list.status_payment_cawangan
                                .file_resit"
                              v-bind:key="receipt"
                            >
                              <div class="row text-center">
                                <receipt-image
                                  v-bind:fileResitId="receipt.id"
                                ></receipt-image>
                                <div>{{ receipt.masa_upload }}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="overflow-x-auto">
                    <table
                      ref="content"
                      class="table text-center table-bordered"
                    >
                      <tbody>
                        <tr class="table-light font-bold">
                          <td>Bil</td>
                          <td style="min-width: 180px">No Rujukan</td>
                          <td style="min-width: 180px">Harga Bidaan</td>
                        </tr>
                        <tr v-for="(data, index) in list.sag" :key="data">
                          <td>{{ index + 1 }}</td>
                          <td>
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#marhunModal"
                              v-on:click="marhunModal(data)"
                            >
                              {{ data.norujukan }}
                            </a>
                          </td>
                          <td>
                            RM
                            {{
                              data.total_payment
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                            }}
                          </td>
                        </tr>
                        <tr class="font-bold uppercase">
                          <td colspan="2">Jumlah</td>
                          <td>
                            RM{{
                              list.total_hargabida
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </AccordionTab>
              </Accordion>
            </template>
          </Card>
          <div
            class="modal fade"
            id="marhunModal"
            tabindex="-1"
            aria-labelledby="marhunModalLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
          >
            <div class="modal-dialog modal-xl modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="grid">
                    <div
                      class="col-12 lg:col-6"
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 300px;
                        min-width: 300px;
                        max-height: 600px;
                        max-width: 600px;
                      "
                    >
                      <item-master-image-modal
                        v-if="theData.filemarhun"
                        v-bind:key="reloadImageComponent"
                        v-bind:norujukan="theData.norujukan"
                        v-bind:filemarhun_id="theData.filemarhun[0].id"
                      ></item-master-image-modal>
                      <p v-else style="text-align: center; width: 100%">
                        Image Unavailable
                      </p>
                    </div>
                    <div class="col-12 text-center lg:col-6">
                      <div class="overflow-x-auto">
                        <table class="table table-bordered">
                          <tbody>
                            <tr class="table-light font-bold">
                              <td>Bil</td>
                              <td style="min-width: 115px">Jenis Marhun</td>
                              <td style="min-width: 42px">Karat</td>
                              <td style="min-width: 42px">Berat</td>
                              <td style="min-width: 102px">Harga Asas</td>
                            </tr>
                            <tr
                              v-for="(data, index) in theData.lelongbidmarhun"
                              v-bind:key="data"
                            >
                              <td>{{ index + 1 }}</td>
                              <td>{{ data.marhun }}</td>
                              <td>{{ data.karat }}</td>
                              <td>{{ data.berat }}g</td>
                              <td>
                                RM{{
                                  data.hargaasas
                                    ?.toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                                }}
                              </td>
                            </tr>
                            <tr style="text-transform: uppercase">
                              <td colspan="3">Jumlah</td>
                              <td>{{ theData.berat }}g</td>
                              <td>
                                RM{{
                                  theData.hargaasas
                                    ?.toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="row justify-content-center font-bold">
                        <div class="col-6">
                          <label class="col-form-label"
                            >Jumlah Harga Bida</label
                          >
                        </div>
                        <div class="col-6">
                          <div class="input-group mb-3">
                            <span class="input-group-text font-bold">RM</span>
                            <span
                              class="form-control input-group-text font-bold"
                              >{{
                                theData.hargabida
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <Button
                    class="p-button-sm"
                    label="Kembali"
                    data-bs-dismiss="modal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          class="
            flex
            align-items-center
            justify-content-center
            container-height
          "
        >
          <div class="grid justify-content-center">
            <div class="col-12 mb-4 text-center">
              <i class="pi pi-book text-8xl text-400"></i>
            </div>
            <div class="col-12 text-center">
              <span class="text-400">Tiada Data.</span>
            </div>
            <div class="col-12 text-center">
              <span class="text-400"
                >Sila rujuk halaman
                <router-link v-bind:to="{ name: 'result' }"
                  >Keputusan</router-link
                >
                untuk maklumat rekod bidaan menang.</span
              >
            </div>
          </div>
        </div>
      </div>
      <Dialog
        header="Surat Hak Kepemilikan Barang"
        v-model:visible="isDisplay"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '50vw' }"
        :modal="true"
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="hee hee"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="800px"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
      >
        <div class="text-center">
          <p id="hakbarang">SURAT KETERANGAN KEPEMILIKAN BARANG</p>
          <p>
            Bahawasanya barang tersebut adalah hak milik
            {{ suratHak.user.name }} ({{ suratHak.user.nokp }})
          </p>
          <table class="table text-center table-bordered">
            <tbody>
              <tr class="table-light font-bold">
                <td>Bil</td>
                <td>No Rujukan</td>
                <td>Jenis Barang</td>
                <td>Berat</td>
                <td>Harga Bidaan</td>
              </tr>
              <tr v-for="(data, index) in suratHak.lelongbid" v-bind:key="data">
                <td>{{ index + 1 }}</td>
                <td>{{ data.norujukan }}</td>
                <td>
                  <div v-for="berat in data.lelongbidmarhun" v-bind:key="berat">
                    <p>{{ berat.marhun_desc }}</p>
                  </div>
                </td>
                <td>
                  <div v-for="berat in data.lelongbidmarhun" v-bind:key="berat">
                    <p>{{ berat.berat }}g</p>
                  </div>
                </td>
                <td>RM{{ data.hargabida }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <template #footer>
          <!-- <Button label="Muat Turun" @click="muatTurunPdf()" /> -->
        </template>
      </Dialog>
    </div>
  </the-template>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
// import VueHtml2pdf from "vue-html2pdf";
import axios from "axios";
import ItemMasterImageModal from "../list/ItemMasterImageModal.vue";
import ReceiptImage from "../pay/ReceiptImage.vue";
import { required, integer, minValue } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  components: {
    "item-master-image-modal": ItemMasterImageModal,
    "receipt-image": ReceiptImage,
  },
  data() {
    return {
      v$: useVuelidate(),
      isLoadingButton: false,
      lists: [],
      tarikhLelong: "",
      loading: false,
      lelongId: "",
      statusPayment: "",
      disabled: 0,
      isSuccess: false,
      theData: "",
      reloadImageComponent: "",
      isDisplay: false,
      suratHak: "",
      isSuccessHak: false,
      isDisplayFileUpload: false,
      fileSize: 0,
      formResit: {
        user_id: localStorage.getItem("id"),
        lelong_id: this.$route.params.lelongId,
        gambarResit: "",
        kodcaw: "",
      },
    };
  },
  validations() {
    return {
      fileSize: { required, integer, minValueValue: minValue(10) },
    };
  },
  created() {
    this.lelongId = this.$route.params.lelongId;
    this.statusPayment = this.$route.params.statusPayment;
  },
  mounted: function () {
    this.liveData();
    this.checkStatusPayment();
  },
  methods: {
    checkStatusPayment() {
      if (this.statusPayment == "" || this.statusPayment == null) {
        this.disabled = 0;
      } else {
        this.disabled = 1;
      }
    },
    liveData() {
      this.loading = true;
      if(this.lelongId != null) {
        axios
          .get("/bayar/" + localStorage.getItem("id") + "/" + this.lelongId, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            // console.log("liveData", response);
            if (response.data.success == true) {
              this.isSuccess = true;
              this.loading = false;
              this.lists = response.data.data;
              this.tarikhLelong = response.data.tarikh_lelong;
            } else {
              this.isSuccess = false;
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      } else {
        this.$router.push({ name: "result" });
      }
    },
    clickPayButton(value) {
      this.$router.push({ name: "pay", params: { lelongId: value } });
    },
    clickPayCdmButton(value) {
      this.$router.push({ name: "pay-cdm", params: { lelongId: value } });
    },
    clickBackButton() {
      this.$router.push({ name: "result" });
    },
    marhunModal(value) {
      this.theData = value;
      this.reloadImageComponent += 1;
    },
    selectPhoto(e) {
      console.log(e.target.files[0].size);
      this.fileSize = e.target.files[0].size;
      this.formResit.gambarResit = e.target.files[0];
    },
    addPhotoResit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.isLoadingButton = true;
        var resitFormData = new FormData();
        resitFormData.append("gambarResit", this.formResit.gambarResit);
        resitFormData.append("lelong_id", this.formResit.lelong_id);
        resitFormData.append("user_id", this.formResit.user_id);
        resitFormData.append("kodcaw", this.formResit.kodcaw);
        console.log(this.formResit);
        axios
          .post("/addPhotoResit", resitFormData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.isLoadingButton = false;
            this.fileSize = 0;
            this.liveData();
            this.isDisplayFileUpload = false;
            if (response.data.success == true) {
              this.$swal.fire({
                icon: "success",
                title: "Gambar Resit Berjaya Di Muat Naik",
                confirmButtonColor: "#344871",
                confirmButtonText: "OK",
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
              this.componentKey += 1;
            } else if (response.data.success == false) {
              this.isLoadingButton = false;
              console.log(response);
              this.$swal.fire({
                toast: true,
                icon: "error",
                title: "Gagal! Sila refresh page semula.",
                animation: false,
                position: "bottom",
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
              });
              this.componentKey += 1;
            }
          })
          .catch((error) => {
            alert(error);
            this.isLoadingButton = false;
          });
      }
    },
    openModalFileUpload(e) {
      this.isDisplayFileUpload = true;
      this.formResit.kodcaw = e;
    },
    closeModalFileUpload() {
      this.isDisplayFileUpload = false;
      this.formResit.kodcaw = "";
    },
    downloadPdf(value) {
      axios
        .get("/resultInCawangan", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            lelong_id: this.lelongId,
            user_id: localStorage.getItem("id"),
            kodcaw: value,
          },
        })
        .then((response) => {
          this.suratHak = response.data;
          this.isDisplay = true;
          this.isSuccessHak = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    muatTurunPdf() {
      // var doc = new jsPDF();

      // doc.html(document.body, {
      //   callback: function (doc) {
      //     doc.save();
      //   },
      //   x: 1,
      //   y: 1,
      // });

      // const doc = new jsPDF();
      // const html = this.$refs.content.innerHTML;

      // doc.html(html, 15, 15, {
      //   width: 150,
      // });

      // doc.save("output.pdf");

      this.$refs.html2Pdf.generatePdf();

      // var imgData = canvas.toDataURL("image/png");
      // var doc = new jsPDF("p", "mm");
      // doc.addImage(imgData, "PNG", 10, 10);
      // doc.save("sample-file.pdf");

      // const doc = new jsPDF();
      // doc.autoTable({ html: "#hak-barang" });
      // const html = this.$refs.hakbarang.innerHTML;
      // doc.fromHTML(html, 15, 15, {
      //   width: 150,
      // });
      // var canvas = document.getElementById("hakbarang");
      // html2canvas(canvas).then(function (canvas) {
      //   var imgdata = canvas.toDataURL("image/png");
      //   var doc = new jsPDF();
      //   doc.addImage(imgdata, "PNG", 500, 500);
      //   doc.save("test.pdf");
      // });
      // window.html2canvas = html2canvas;
      // var doc = new jsPDF("p", "pt", "a4");
      // doc.html(document.querySelector(#hakbarang), {
      //   callback: function(pdf) {
      //     pdf.save("thehehehe.pdf")
      //   }
      // })
      // doc.save("table.pdf");
    },
  },
};
</script>

<style scoped>
.toolbar-custom {
  background: #fff !important;
}

.container-height {
  min-height: 70vh;
}

.div-card-bank {
  display: flex;
  justify-content: center;
}

.card-bank {
  background: #2f68c51c;
  color: #2f68c5f2;
  width: 300px;
  height: 125px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>