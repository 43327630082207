<template>
  <TabView>
    <TabPanel
      v-for="data in namaCawangan"
      v-bind:key="data"
      :header="data.cawangan"
    >
      <div>
        <div class="overflow-x-auto">
          <table class="table text-center table-bordered table-hover">
            <tbody>
              <tr class="table-light">
                <td>Bil</td>
                <td style="min-width: 240px">No Rujukan</td>
                <!-- <td style="min-width: 210px">Marhun</td> -->
                <td style="min-width: 120px">Harga Bida</td>
              </tr>
              <tr v-for="(item, index) in data.sag" v-bind:key="item">
                <td>{{ index + 1 }}</td>
                <td>{{ item.norujukan }}</td>
                <!-- <td>{{ item.marhun }}</td> -->
                <td>
                  RM{{
                    item.total_payment
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                  }}
                </td>
              </tr>
              <tr class="uppercase">
                <th colspan="2">Jumlah</th>
                <th>
                  RM{{
                    data.total_hargabida
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
                  }}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="grid mt-5">
          <!-- <button class="btn btn-danger" v-on:click="clickCancelButton()">
            Cancel
          </button> -->
          <div class="col-12 md:col-5">
            <Button
              label="Kembali"
              class="p-button-secondary p-button-sm"
              v-on:click="clickCancelButton()"
            />
          </div>
          <div class="sm:none md:col"></div>
          <div class="col-12 md:col-5 md:text-right">
            <Button
              label="Bayar Bidaan Cawangan Ini"
              class="p-button-sm p-button-success"
              @click="openModal(data)"
            />
          </div>
          <!-- <button
            ref="nextButton"
            v-on:click="clickNextButton()"
            class="btn btn-primary"
          >
            Teruskan
          </button> -->
        </div>
      </div>
    </TabPanel>
  </TabView>
  <Dialog
    header="Teruskan Bayaran Menggunakan Kaedah FPX"
    v-model:visible="isDisplay"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
  >
    <p>Adakah anda pasti untuk membuat bayaran bagi cawangan ini?</p>
    <div class="overflow-x-auto">
      <table class="table text-center table-bordered">
        <tbody>
          <tr>
            <td>Cawangan</td>
            <td>{{ cawanganPilihan }}</td>
          </tr>
          <tr>
            <td>Harga Bida</td>
            <td style="min-width: 150px">
              RM{{
                hargaBidaanPilihan
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
              }}
            </td>
          </tr>
          <tr>
            <td>Fi Pengurusan</td>
            <td>RM1.00</td>
          </tr>
          <tr>
            <th>Jumlah Keseluruhan</th>
            <th>
              RM{{
                hargaBidaanPilihanTambahFi
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ""
              }}
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <template #footer>
      <Button
        label="Tidak"
        icon="pi pi-times"
        iconPos="right"
        @click="closeModal()"
        class="p-button-text"
      />
      <!-- <Button
        label="Ya"
        icon="pi pi-check"
        iconPos="right"
        :loading="isLoading"
        @click="doPayment()"
      /> -->
      <Button
        label="Teruskan"
        icon="pi pi-check"
        iconPos="right"
        @click="openModalTNC()"
      />
    </template>
  </Dialog>
  <Dialog
    header="Makluman"
    v-model:visible="isDisplayTNC"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
  >
    <div class="text-center m-2">
      <!-- <terms-and-conditions-details></terms-and-conditions-details> -->
      <h1><i class="bi bi-exclamation-triangle text-warning"></i></h1>
      <h6 class="text-danger">
        Pembida berjaya hendaklah menyelesaikan keseluruhan amaun bidaan dan
        mengambil marhun pada/sebelum jam 4.00 petang.
      </h6>
    </div>
    <template #footer>
      <Button
        label="Batal Pembayaran"
        icon="pi pi-times"
        iconPos="right"
        @click="closeModalTNC()"
        class="p-button-text"
      />
      <!-- <Button
        label="Ya"
        icon="pi pi-check"
        iconPos="right"
        :loading="isLoading"
        @click="doPayment()"
      /> -->
      <Button
        label="Teruskan Bayaran"
        icon="pi pi-check"
        iconPos="right"
        :loading="isLoading"
        @click="doPayment()"
      />
    </template>
  </Dialog>
  <Dialog
    v-model:visible="isDisplayPaymentMethod"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <div class="text-center m-2">
      <h4>Sila Pilih Jenis Bayaran</h4>
      <Button
        label="Bayaran FPX"
        @click="openModalNew()"
        class="p-button-warning m-1"
      />
      <Button
        label="Bayaran CDM"
        @click="openModalFileUpload()"
        class="p-button-help m-1"
      />
    </div>
    <template #footer>
      <Button
        label="Batal"
        icon="pi pi-times"
        iconPos="right"
        @click="closeModalPaymentMethod()"
        class="p-button-text"
      />
    </template>
  </Dialog>
  <Dialog
    header="Teruskan Bayaran Menggunakan Kaedah CDM"
    v-model:visible="isDisplayFileUpload"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
  >
    <div class="text-center">
      <div>Lakukan bayaran ke akaun tersebut.</div>
      <div class="div-card-bank">
        <div class="card-bank">
          <div>
            <div>Koperasi Rakyat Kelantan Berhad</div>
            <div>1101515411</div>
            <div>BANK RAKYAT</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5">Sila muat naik gambar resit dalam bentuk <strong>PNG</strong> atau <strong>JPG</strong>.</div>
    <div>Pastikan gambar yang dimuat naik adalah <strong>JELAS.</strong></div>
    <div class="text-center m-2">
      <input
        class="form-control"
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        v-on:change="selectPhoto"
      />
      <div
        style="font-size: 13px"
        class="fst-italic text-danger row"
        v-if="v$.fileSize.$error"
      >
        <div>Jenis gambar yang diterima adalah PNG dan JPG.</div>
      </div>
    </div>
    <template #footer>
      <Button
        class="p-button-sm p-button-secondary"
        label="Kembali"
        @click="closeModalFileUpload()"
      />
      <Button
        class="p-button-sm p-button-success"
        label="Simpan"
        icon="pi pi-check"
        iconPos="right"
        :loading="isLoadingButton"
        @click="addPhotoResit()"
      />
    </template>
  </Dialog>
</template>

<script>
import SelectId from "./SelectId.vue";
import TermsAndConditionsDetails from "../authentication/TermsAndConditionsDetails.vue";
import axios from "axios";
import { required, integer, minValue} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  components: {
    "select-id": SelectId,
    "terms-and-conditions-details": TermsAndConditionsDetails,
  },
  props: {
    namaCawangan: "",
    lelongIdProps: "",
    // invoiceIdProps: "",
    // invoiceNoProps: "",
  },
  data() {
    return {
      v$: useVuelidate(),
      nextButton: false,
      selectCawangan: this.namaCawangan,
      selectRadio: "",
      isLoadingButton: false,
      isDisplay: false,
      isDisplayTNC: false,
      isDisplayPaymentMethod: false,
      isDisplayFileUpload: false,
      isLoading: false,
      cawanganPilihan: "",
      hargaBidaanPilihan: "",
      hargaBidaanPilihanTambahFi: "",
      lelongDetails: "",
      fileSize: 0,
      form: {
        email: localStorage.getItem("email"),
        notel: localStorage.getItem("notelbimbit"),
        name: localStorage.getItem("name"),
        user_id: localStorage.getItem("id"),
        lelong_id: "",
        invoice_id: "",
        invoice_no: "",
        total: integer,
        // sag: [
        //   {
        //     norujukan: "APM211121-0015",
        //     kodcaw: "APM",
        //     total_payment: 1329,
        //   },
        //   {
        //     norujukan: "APM211120-0018",
        //     kodcaw: "APM",
        //     total_payment: 1443,
        //   },
        // ],
        sag: "",
        kodcaw: "",
        gambarResit: "",
      },
    };
  },
  validations() {
    return {
      fileSize: { required, integer, minValueValue: minValue(10) },
    };
  },
  mounted() {
    this.getInvoice();
  },
  methods: {
    getInvoice() {
      axios
        .get("/invoice", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            user_id: localStorage.getItem("id"),
            lelong_id: this.lelongIdProps,
          },
        })
        .then((response) => {
          // console.log("getInvoice", response);
          if (response.data.success == true) {
            this.form.invoice_id = response.data.invoice.id;
            this.form.invoice_no = response.data.invoice.invoice_no;
            this.form.lelong_id = this.lelongIdProps;
            this.lelongDetails = response.data.lelong;
          } else if (response.data.success == false) {
            alert("No Invoice Id");
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    openModal(value) {
      var date = new Date();
      var current_time = date.toLocaleString();
      var limit_masa_bayaran = "15:00:00";
      var limit_datetime_bayaran =
        this.lelongDetails.tarikh_lelong + ", " + limit_masa_bayaran;
      // console.log(current_time, "\n", limit_datetime_bayaran);

      // if (current_time < limit_datetime_bayaran === true) {
      this.isDisplayPaymentMethod = true;
      this.cawanganPilihan = value.cawangan;
      this.form.kodcaw = value.kodcaw;
      this.hargaBidaanPilihan = value.total_hargabida;
      let convertHargaBidaanPilihan =
        parseFloat(value.total_hargabida.replace(/,/g, "")) + 1;
      this.hargaBidaanPilihanTambahFi = convertHargaBidaanPilihan.toFixed(2);
      this.form.total = parseFloat(value.total_hargabida.replace(/,/g, ""));
      let convertData = JSON.parse(JSON.stringify(value.sag));
      this.form.sag = convertData;
      // } else {
      //   this.$swal.fire({
      //     icon: "error",
      //     title: "Gagal",
      //     text: "Pembayaran mesti diselesaikan sebelum 3:00pm.",
      //     confirmButtonColor: "#344871",
      //     confirmButtonText: "Kembali",
      //     allowOutsideClick: false,
      //     allowEscapeKey: false,
      //   });
      // }
    },
    closeModal() {
      this.isDisplay = false;
      this.form.total = "";
      this.form.sag = "";
    },
    selectPhoto(e) {
      this.fileSize = e.target.files[0].size;
      this.form.gambarResit = e.target.files[0];
    },
    addPhotoResit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let convertDataSAG = JSON.stringify(this.form.sag);

        this.isLoadingButton = true;
        var resitFormData = new FormData();
        resitFormData.append("gambarResit", this.form.gambarResit);
        resitFormData.append("lelong_id", this.form.lelong_id);
        resitFormData.append("user_id", this.form.user_id);
        resitFormData.append("kodcaw", this.form.kodcaw);
        resitFormData.append("invoice_id", this.form.invoice_id);
        resitFormData.append("invoice_no", this.form.invoice_no);
        resitFormData.append("total", this.form.total);
        resitFormData.append("sag", convertDataSAG);
        axios
          .post("/initializeCdmPayment", resitFormData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.isLoadingButton = false;
            this.fileSize = 0;
            this.isDisplayFileUpload = false;
            if (response.data.success == true) {
              this.$swal.fire({
                icon: "success",
                title: "Gambar Resit Berjaya Di Muat Naik",
                text: "Marhun mesti dituntut sebelum jam 4 petang hari ini.",
                confirmButtonColor: "#344871",
                confirmButtonText: "OK",
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
              this.$router.push({ name: "result" });
            } else {
              this.isLoadingButton = false;
              console.log(response);
              this.$swal.fire({
                toast: true,
                icon: "error",
                title: "Gagal! Sila cuba kembali.",
                animation: false,
                position: "bottom",
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
              });
            }
          })
          .catch((error) => {
            alert(error);
            this.isLoadingButton = false;
          });
      }
    },
    openModalNew() {
      this.isDisplay = true;
      this.isDisplayPaymentMethod = false;
    },
    openModalFileUpload() {
      this.isDisplayFileUpload = true;
      this.isDisplayPaymentMethod = false;
    },
    closeModalFileUpload() {
      this.isDisplayFileUpload = false;
    },
    openModalTNC() {
      this.isDisplay = false;
      this.isDisplayTNC = true;
    },
    closeModalTNC() {
      this.isDisplayTNC = false;
      this.form.total = "";
      this.form.sag = "";
    },
    openModalPaymentMethod() {
      this.isDisplayPaymentMethod = true;
    },
    closeModalPaymentMethod() {
      this.isDisplayPaymentMethod = false;
    },
    clickRadio(value) {
      // console.log(value);
      this.selectRadio = value;
    },
    clickCancelButton() {
      this.$router.push({
        name: "detail",
        params: {
          lelongId: this.lelongIdProps,
          statusPayment: null,
        },
      });
    },
    clickNextButton() {
      this.nextButton = true;
    },
    backIsClick(value) {
      this.nextButton = value;
    },
    doPayment() {
      console.log(this.form);
      this.isLoading = true;
      axios
        .post("/bidpayment", this.form)
        .then((response) => {
          if (response.data.success == true) {
            console.log(response);
            window.location.href = response.data.message + "?auto_submit=true";
          } else {
            console.log(response);
            this.isLoading = false;
          }
        })
        .catch((response) => {
          this.isLoading = false;
          alert(response.message);
          console.log(response);
        });
    },
  },
};
</script>

<style scoped>
.div-card-bank {
  display: flex;
  justify-content: center;
}

.card-bank {
  background: #2f68c51c;
  color: #2f68c5f2;
  width: 300px;
  height: 125px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>