<template>
  <the-template>
    <!-- <button
      v-on:click="toggleReloadComponent()"
      class="badge btn-secondary m-2"
    >
      Reload <i class="bi bi-arrow-clockwise"></i>
    </button> -->
    <cart-list-new
      v-bind:key="componentKey"
      v-on:reloadComponent="toggleReloadComponent"
    ></cart-list-new>
  </the-template>
</template>

<script>
import CartListNew from "./CartListNew.vue";

export default {
  components: {
    "cart-list-new": CartListNew,
  },
  data() {
    return {
      componentKey: 0,
    };
  },
  mounted: function () {
    if (localStorage.getItem("reloaded")) {
      localStorage.removeItem("reloaded");
    } else {
      localStorage.setItem("reloaded", "1");
      location.reload();
    }
  },
  methods: {
    toggleReloadComponent() {
      this.componentKey += 1;
    },
  },
};
</script>
