<template>
  <div v-if="isLoading" class="fadein animation-duration-400">
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="responseData" class="fadein animation-duration-400">
      <InlineMessage class="mb-2" severity="info"
        >Kemaskini pada {{ updatedTime }}</InlineMessage
      >

      <div class="overflow-x-auto">
        <table class="table table-bordered table-hover">
          <tbody>
            <tr class="table-light font-bold">
              <th>Bil</th>
              <th>Karat</th>
              <th style="min-width: 100px">Harga</th>
            </tr>
            <tr v-for="(data, index) in listKarat" v-bind:key="data.id">
              <td>{{ index + 1 }}</td>
              <td>{{ data.karat }}</td>
              <td>RM {{ data.harga2 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
      <table class="table table-bordered table-hover">
        <tbody>
          <tr class="table-light font-bold">
            <td scope="col">Bil</td>
            <td scope="col">Karat</td>
            <td scope="col">Harga</td>
          </tr>
          <tr>
            <td colspan="5" class="text-center text-500">Tiada Data</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useAuthenticationUserStore } from "@/store/modules/authentication";

export default {
  data() {
    return {
      listKarat: Array,
      updatedTime: String,
      isLoading: false,
      responseData: false,
    };
  },
  created() {
    this.getKarat();
  },
  methods: {
    async getKarat() {
      this.isLoading = true;
      await axios
        .get("/karat", {
          headers: {
            Authorization: "Bearer " + useAuthenticationUserStore().getToken,
          },
        })
        .then((response) => {
          // console.log("getKarat", response);
          if (response.data.success == true) {
            this.updatedTime = response.data.update_time;
            this.listKarat = response.data.karat;
            this.isLoading = false;
            this.responseData = true;
          } else {
            this.isLoading = false;
            this.responseData = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>